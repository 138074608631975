// Full list if stripes list (as of Aug 2, 2023)
// Commented out ones are ones we want to exclude
const STRIPE_SUPPORTED_COUNTRIES = [
  { code: 'AU', name: 'Australia' },
  { code: 'AT', name: 'Austria' },
  { code: 'BE', name: 'Belgium' },
  // { code: 'BR', name: 'Brazil' },
  // { code: 'BG', name: 'Bulgaria' },
  { code: 'CA', name: 'Canada' },
  { code: 'HR', name: 'Croatia' },
  // { code: 'CY', name: 'Cyprus' },
  // { code: 'CZ', name: 'Czech Republic' },
  { code: 'DK', name: 'Denmark' },
  // { code: 'EE', name: 'Estonia' },
  { code: 'FI', name: 'Finland' },
  { code: 'FR', name: 'France' },
  { code: 'DE', name: 'Germany' },
  // { code: 'GI', name: 'Gibraltar' },
  { code: 'GR', name: 'Greece' },
  // { code: 'HK', name: 'Hong Kong' },
  { code: 'HU', name: 'Hungary' },
  { code: 'IN', name: 'India' },
  // { code: 'ID', name: 'Indonesia' },
  { code: 'IE', name: 'Ireland' },
  { code: 'IT', name: 'Italy' },
  // { code: 'JP', name: 'Japan' },
  // { code: 'LV', name: 'Latvia' },
  // { code: 'LI', name: 'Liechtenstein' },
  // { code: 'LT', name: 'Lithuania' },
  { code: 'LU', name: 'Luxembourg' },
  // { code: 'MY', name: 'Malaysia' },
  // { code: 'MT', name: 'Malta' },
  { code: 'MX', name: 'Mexico' },
  { code: 'NL', name: 'Netherlands' },
  { code: 'NZ', name: 'New Zealand' },
  { code: 'NO', name: 'Norway' },
  { code: 'PL', name: 'Poland' },
  { code: 'PT', name: 'Portugal' },
  // { code: 'RO', name: 'Romania' },
  { code: 'SG', name: 'Singapore' },
  // { code: 'SK', name: 'Slovakia' },
  // { code: 'SI', name: 'Slovenia' },
  { code: 'ES', name: 'Spain' },
  { code: 'SE', name: 'Sweden' },
  { code: 'CH', name: 'Switzerland' },
  // { code: 'TH', name: 'Thailand' },
  // { code: 'AE', name: 'United Arab Emirates' },
  { code: 'GB', name: 'United Kingdom' },
  { code: 'US', name: 'United States' },
];

const ADDITIONAL_SUPPORTED_COUNTRIES = [
  { code: 'GB-SCT', name: 'Scotland', stripeCountryCode: 'GB' },
];

const SUPPORTED_CREATOR_COUNTRIES = [
  ...STRIPE_SUPPORTED_COUNTRIES,
  ...ADDITIONAL_SUPPORTED_COUNTRIES,
];

const SUPPORTED_CREATOR_COUNTRY_CODES = SUPPORTED_CREATOR_COUNTRIES.map(country => country.code);
export default SUPPORTED_CREATOR_COUNTRIES;

export const isCreatorCountrySupported = (countryCode: string | null): boolean => (
  !countryCode
  || SUPPORTED_CREATOR_COUNTRY_CODES.includes(countryCode)
);