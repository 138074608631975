import CustomLink from '@components/CustomLink';
import Shimmer from '@components/Shimmer';
import { useToast } from '@hooks';
import dashboardContext from '@layouts/DashboardLayout/DashboardContext';
import classNames from 'classnames';
import { Fragment, useContext, useEffect, useState } from 'react';
import s from './PageContext.module.scss';

type Items = Array<{
  id: string;
  label?: string;
  value: string;
  href?: string;
}>;

const PageContext: React.FC<{
  items: Promise<Items> | Items;
  isFetching?: boolean;
  collapsible?: boolean;
  label?: string;
  cantCopy?: boolean;
}> = ({ items, isFetching, collapsible, label, cantCopy }) => {
  const [loadedItems, setLoadedItems] = useState<Items>([]);
  const addToast = useToast();
  const { isInDashboard } = useContext(dashboardContext);
  async function copyToClipboard(text: string): Promise<void> {
    try {
      await navigator.clipboard.writeText(text);
      addToast(useToast.ToastTypeVariants.SUCCESS, `Copied to clipboard!`);
    } catch (err) {
      console.error('Failed to copy text: ', err);
    }
  }

  useEffect(() => {
    if (items instanceof Promise) {
      items.then(data => {
        setLoadedItems(data);
      });
    }
  }, [items]);

  if (isFetching || (items instanceof Promise && !loadedItems)) {
    if (collapsible) {
      return (
        <details className={s.collapsible}>
          <summary className={s.collapsibleInactive}>Loading {label || 'Context'}...</summary>
        </details>
      );
    }
    return (
      <div className={s.context}>
        <Shimmer className={s.shimmer} />
        <Shimmer className={s.shimmer} />
        <Shimmer className={s.shimmer} />
      </div>
    );
  }

  if (!isFetching && (items instanceof Promise ? loadedItems.length === 0 : items.length === 0)) {
    return <></>;
  }

  const Wrapper = collapsible ? 'details' : Fragment;

  const ButtonOrDiv = cantCopy ? 'div' : 'button';

  return (
    <Wrapper
      {...(collapsible && {
        className: s.collapsible,
      })}
    >
      {Boolean(collapsible) && <summary {...(Array.isArray(items) && items.length === 0) && {
        className: s.collapsibleInactive,
      }}>{label || 'Context'}</summary>}
      <ul className={`${s.context} no-scrollbar ${Boolean(collapsible) && s.collapsiblePopover || ''}`}>
        {(items instanceof Promise ? loadedItems : items)?.map((item) => {
          if (!item.value) {
            return null;
          }
          const isLink = Boolean(item.href && isInDashboard);
          const Component = isLink ? CustomLink : Fragment;
          return (
            <li
              key={`${item.id}`}
              className={classNames({
                [s.contextItemLink]: item.href,
                [s.contextItemNoLabel]: !item.label,
              })}
            >
              {item.label && (
                <ButtonOrDiv className={s.contextLabel} title="Copy to clipboard" onClick={!cantCopy ? () => copyToClipboard(item.value) : undefined}>
                  {item.label}:{' '}
                </ButtonOrDiv>
              )}
              <Component
                {...(isLink
                  ? {
                    to: item.href,
                  }
                  : {})}
              >
                {item.value}
              </Component>
            </li>
          );
        })}
      </ul>
    </Wrapper>
  );
};

export default PageContext;
