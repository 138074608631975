import { useContext } from 'react';
import { useUser } from '@hooks';
import useCanEditNoodle from '@hooks/useCanEditNoodle';
import DocumentAccessContext from './Context';
import ForbiddenToCustomer from './ForbiddenToCustomer';
import ForbiddenToCreator from './ForbiddenToCreator';

type Props = {
  size?: 'sm' | 'lg';
  documentCreatorId: string | null;
  documentPersonId: string | null;
  isCollaborator: boolean;
  isCenteredVertically?: boolean;
};

const Gate: React.FC<Props> = ({ children, documentCreatorId, documentPersonId, isCollaborator, size, isCenteredVertically }) => {
  const [user] = useUser();
  const { accessToken } = useContext(DocumentAccessContext);

  const isThisCreator = useCanEditNoodle({ creator: documentCreatorId ? { id: documentCreatorId } : null });
  const isTheFileOwner = Boolean(user && documentPersonId && user.id === documentPersonId);

  if (isTheFileOwner || isCollaborator || (isThisCreator && accessToken)) {
    return <>{children}</>;
  }

  if (isThisCreator) {
    return <ForbiddenToCreator size={size} isCenteredVertically={isCenteredVertically}/>;
  }
  return <ForbiddenToCustomer />;
};

export default Gate;
