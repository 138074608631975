import { ReactElement } from 'react';
import Modal from '@components/Modal';
import ForgotPasswordForm from '@components/Auth/ForgotPasswordForm';
import { View } from '@components/Auth/types';
import s from './ForgotPasswordModal.module.scss';

type Props = {
  creatorSlug?: string;
  userEmail: string;
  onClose: () => void;
  onChangeView: (view: View) => void;
};

const ForgotPasswordModal = ({ creatorSlug, onClose, onChangeView, userEmail }: Props): ReactElement => (
  <Modal
    hideBottomInset
    title='Reset Password'
    onClose={onClose}
    hasForm={false}
  >
    <div className={s.forgotPasswordPage}>
      <ForgotPasswordForm creatorSlug={creatorSlug} userEmail={userEmail} onChangeView={onChangeView}/>
    </div>
  </Modal>
);

export default ForgotPasswordModal;
