import classNames from 'classnames';
import { CSSProperties, FC, ReactElement } from 'react';

import s from './Card.module.scss';

type CardProps = {
  title?: string;
  caption?: string;
  isBordered?: boolean;
  hasShadow?: boolean;
  className?: string;
  style?: CSSProperties;
};

const Card:FC<CardProps> = ({ title, caption, children, isBordered, hasShadow, className, style, ...restProps }): ReactElement => (
  <div className={classNames(s.card, {
    [s['card--is-bordered']]: isBordered,
    [s['card--has-shadow']]: hasShadow,
  }, className)} style={style} {...restProps}>
    {title && (
      <div className={s.card__title}>
        <strong className='heading-sm'>{title}</strong>
      </div>
    )}
    {children}
    {caption && (
      <div className={s.card__caption}>
        <strong className='heading-sm'>{caption}</strong>
      </div>
    )}
  </div>
);

export default Card;
