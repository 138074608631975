import React, { FC } from 'react';
import { Creator, NoodleProductTypes, Price, Product, ProductType } from '@typings/graphql-models';
import ProfileProductIcons from '@components/ProfileProductIcons';
import Spacer from '@components/Spacer';
import Image from '@components/Image';
import SaaSTierIcon from '@components/SaaSTierIcon';
import classNames from 'classnames';
import * as format from '@format';
import getDiscountedAmount from '@helpers/getDiscountedAmount';
import CalendarLink from 'react-icalendar-link';
import { Booking, DiscountCode, DiscountCodeProduct } from '@typings/api-models';
import SvgCreditCard from '@components/Icons/PaymentMethodLogo/CreditCard';
import Buttons from '@components/Buttons';
import s from './PurchaseConfirmationCard.module.scss';

type Props = {
  onClick?: () => void;
  price?: Pick<Price, 'currency' | 'price' | 'priceTitle'> & {
    product?: Pick<Product, 'id' | 'title'> & {
      creator?: Pick<Creator, 'timezone'> | null;
      productTypes?: Pick<ProductType, 'noodleProductType'>[] | null;
      image?: {
        url: string;
      } | null;
    } | null;
  } | null;
  isSaaSProduct?: boolean;
  tier?: string;
  bookings?: Pick<Booking, 'id' | 'startAt' | 'endAt'>[];
  discountCode?: Pick<DiscountCode, 'id' | 'amountOff' | 'percentOff' | 'code' | 'expirationDate'> & { products: Pick<DiscountCodeProduct, 'productId'>[] } | null;
  paymentAmount?: number;
  paymentType?: string;
  paymentMethod?: string | null;
};

const PurchaseConfirmationCard: FC<Props> = ({
  onClick,
  price,
  isSaaSProduct,
  tier,
  bookings,
  discountCode,
  paymentAmount,
  paymentType,
  paymentMethod,
}) => (
  <Buttons
    isWrapper
    onClick={onClick}
    className={s.container}
  >
    {[NoodleProductTypes.Lite, NoodleProductTypes.InPersonSession, NoodleProductTypes.OnlineSession].includes(
        price?.product?.productTypes?.map(t => t.noodleProductType)[0] as NoodleProductTypes,
    )
      ? (
        <ProfileProductIcons
          isOnCreatorPage
          creatorImage={undefined}
          creatorName={'Creator'}
          type={price?.product?.productTypes?.map(t => t.noodleProductType)[0] || NoodleProductTypes.Lite}
        />
      )
      : (
        <>
          {price?.product?.image?.url && (
            <>
              <Spacer size={'20px'} />
              <Image alt={price?.product.title || undefined} image={price?.product.image.url} />
            </>
          )}
        </>
      )}
    {isSaaSProduct && tier && (
      <SaaSTierIcon label={tier || ''} hasCircle />
    )}
    <Spacer size="16px" />
    <h2 className="body-md-bold">{isSaaSProduct && tier && `Noodle ${tier}` || price?.product?.title || price?.priceTitle}</h2>
    <div className={classNames('body-sm', s.paid)}>
      {price && format.price.withCurrency(getDiscountedAmount(price, discountCode, paymentAmount), price?.currency)}
      <div className={s.paidWith}>
        <span className='caption'>Paid with</span>
        {paymentType === 'card' && (
          <>
            <SvgCreditCard />
            <span className="caption">*{paymentMethod}</span>
          </>
        )}
        {paymentType === 'appsumo' && (
          <span className='caption'> AppSumo</span>
        )}
        {paymentType === 'ach' && (
          <span className='caption'> ACH (*{paymentMethod})</span>
        )}
      </div>
    </div>
    {bookings?.map(booking => Boolean(booking.startAt) && (
      <div key={booking.id} className={s.booking}>
        <p className='caption'>Date and Time:</p>
        <Spacer size='8px' />
        <p className='body-md'>
          {format.datetime.withTimezone({ datetime: booking.startAt || '', timezone: price?.product?.creator?.timezone })}
        </p>
        <Spacer size='32px' />
        <CalendarLink
          filename={price?.product?.title || 'Booking'}
          event={{
            endTime: booking.endAt || '',
            startTime: booking.startAt || '',
            title: price?.product?.title || 'Booking',
          }}
        >
          <div className={s.calendarButton}>
            <span className='body-md-bold'>Add to your calendar</span>
          </div>
        </CalendarLink>
      </div>
    ))}
  </Buttons>
);

export default PurchaseConfirmationCard;
