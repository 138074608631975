import { useRouter } from 'next/router';
import { ReactNode, useRef } from 'react';
import classNames from 'classnames';
import CaretCircleDown from '@components/Icons/CaretCircleDown';
import type { Icon } from '@phosphor-icons/react';
import Action from './Action';
import Breadcrumb from './BreadCrumb';
import s from './Header.module.scss';
import TabText from '../Atoms/TabText';
import SecondaryActions from '../SecondaryActions';
import Switch from '../Atoms/Switch';

const Header: React.FC<{
  title?: ReactNode;
  description?: ReactNode;
  hierarchy?: 'h1' | 'h2' | 'h3';
  breadcrumbs?: Array<Parameters<typeof Breadcrumb>[0]['breadcrumb']>;
  tabs?: Array<Parameters<typeof TabText>[0]>;
  actions?: Array<Parameters<typeof Action>[0]['action']>;
  secondaryActions?: Array<Parameters<typeof Action>[0]['action']>;
  noDivider?: boolean;
  className?: string;
  isSticky?: boolean;
  Icon?: Icon;
  Tag?: 'div' | 'summary';
  aiBg?: boolean;
  iconColor?: string;
  actionsComponent?: ReactNode;
  ariaLabel?: string;
  toggle?: {
    isChecked: boolean;
    label: string;
    onChange: (isChecked: boolean) => void;
  };
  topRightComponent?: ReactNode;
}> = ({
  className,
  breadcrumbs,
  title,
  description,
  hierarchy = 'h1',
  tabs,
  actions,
  secondaryActions,
  topRightComponent,
  noDivider,
  isSticky,
  Icon,
  Tag = 'div',
  aiBg,
  iconColor,
  actionsComponent,
  ariaLabel,
  toggle,
}) => {
  const toggleOnChangeRef = useRef(toggle?.onChange);
  toggleOnChangeRef.current = toggle?.onChange;
  const hasBreadcrumbs = breadcrumbs && breadcrumbs.length > 0;
  const hasTabs = tabs && tabs.length > 0;
  const hasActions = (actions && actions.length > 0) || actionsComponent;
  const hasSecondaryActions = secondaryActions && secondaryActions.length > 0;

  const router = useRouter();

  return (
    <Tag
      className={classNames(s.header, className)}
      style={{
        // ...(hierarchy === 'h1' && !noDivider ? { justifyContent: 'space-between', minHeight: 112 } : {}),
        ...(isSticky ? { position: 'sticky', top: 0 } : {}),
        ...(noDivider ? { boxShadow: 'none', paddingBottom: 0, position: 'relative' } : {}),
        ...(aiBg ? { backgroundImage: `var(--ai-bg-20)` } : {}),
      }}
    >
      {(hasBreadcrumbs || title || description || hasSecondaryActions) && (
        <div className={s.top}>
          {(hasBreadcrumbs || title || description) && (
            <div className={s.topContent}>
              {hasBreadcrumbs && (
                <div className={s.breadcrumbs}>
                  {breadcrumbs!.map((b, idx) => (
                    <Breadcrumb key={`breadcrumb-${b.label}`} breadcrumb={b} isLast={idx === breadcrumbs!.length - 1} />
                  ))}
                </div>
              )}
              {(title || description) && (
                <div>
                  <h2 className={(hierarchy === 'h1' && 'heading-sm') || (hierarchy === 'h2' && 'body-lg-bold') || 'body-md-bold'}>
                    {Tag === 'summary' && <CaretCircleDown weight="fill" />}
                    {Icon && <Icon size={24} weight={iconColor ? 'fill' : 'regular'} color={iconColor} />}
                    {title}
                  </h2>
                  {typeof description === 'string' && <p className={classNames(s.description, hierarchy === 'h3' && 'caption')}>{description}</p>}
                  {typeof description === 'object' && description}
                </div>
              )}
            </div>
          )}
          {topRightComponent && <div className={s.topRightContainer}>{topRightComponent}</div>}
          {hasSecondaryActions && (
            <SecondaryActions
              ariaLabel={ariaLabel ?? "Secondary actions"}
              hideTooltip={!ariaLabel}
              position="bottom-right"
              onSelect={e => {
                const selectedAction = secondaryActions?.find(a => a.label === e);
                if (selectedAction) {
                  if ('href' in selectedAction && selectedAction.href) {
                    router.push(selectedAction.href);
                  } else if (selectedAction.onClick) {
                    selectedAction.onClick();
                  }
                }
              }}
              options={
                secondaryActions?.map(a => ({
                  destructive: a.isDestructive,
                  icon: a.icon,
                  value: a.label,
                })) || []
              }
              className={s.secondaryActions}
            />
          )}
        </div>
      )}
      {toggle && (
        <Switch name="toggleSwitch" isChecked={toggle.isChecked} label={toggle.label} onChange={toggle.onChange} className={s.toggle} />
      )}
      {hasTabs && (
        <div className={`${s.tabs} no-scrollbar`}>
          {tabs!.map(t => (
            <TabText key={`tab-${t.label}`} label={t.label} icon={t.icon} selected={t.selected} {...(t.onClick ? { onClick: t.onClick } : { href: t.href })} />
          ))}
        </div>
      )}
      {hasActions && (
        <div className={s.actions}>
          {actionsComponent}
          {actions && actions.length > 0 && actions.map(a => (
            <Action key={`action-${a.label}`} action={a} />
          ))}
        </div>
      )}
    </Tag>
  );
};

export default Header;
