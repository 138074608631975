import React, { ReactElement } from 'react';
import UserImage from '@components/UserImage';
import ProductIcon from '@components/ProductIcon';
import { NoodleProductTypes } from '@typings/graphql-models';
import { useIsMobile } from '@hooks';
import s from './ConnectWithCreatorTitle.module.scss';

type Props = {
  creatorName?: string | null;
  creatorImageUrl?: string | null;
  productIcon?: NoodleProductTypes;
};

const ConnectWithCreatorTitle = ({ creatorImageUrl, creatorName, productIcon }: Props): ReactElement => {
  const isMobile = useIsMobile();
  return (
    <div className={s.connect_title_body}>
      <div className={s.connect_title_image}>
        <UserImage url={creatorImageUrl} name={creatorName} size={isMobile ? 56 : 120} />
        {productIcon && <div className={s.connect_title_image_icon}>
          <ProductIcon size={24} noodleProductType={productIcon} />
        </div>}
      </div>
      <h1 className='heading-sm'>Connect with <br />{creatorName}</h1>
    </div>

  ); };

export default ConnectWithCreatorTitle;
