import { DiscountCode, DiscountCodeProduct } from '@typings/api-models';
import { Price } from '@typings/graphql-models';

const getDiscountedAmount = (price: Pick<Price, 'price'> & { product?: { id: string } | null }, discount?: Pick<DiscountCode, 'percentOff' | 'amountOff'> & { products: DiscountCodeProduct[] } | null, paymentAmount?: number): number => {
  let finalPrice = paymentAmount || price.price;
  if (discount?.products?.find((p) => p.productId === price.product?.id)) {
    if (discount?.percentOff) {
      finalPrice = Number((finalPrice * (100 - discount.percentOff) / 100).toFixed(2));
    }
    if (discount?.amountOff) {
      finalPrice -= discount.amountOff;
    }
  }
  return finalPrice;
};

export default getDiscountedAmount;
