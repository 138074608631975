import { useEffect, useState } from 'react';
import Button from '@components/Buttons';
import Tabs from '@components/DesignLibrary/Tabs';
import authStyles from '../Auth.module.scss';
import { View } from '../types';
import SignInWithPhone from './SignInWithPhone';
import SignInWithEmail from './SignInWithEmail';
import TermsAndPolicy from '../TermsAndPolicy';

type Props = {
  creatorSlug?: string;
  onChangeView: (view: View, data?: { email: string }) => void;
  initialAuthMethod?: 'email' | 'phone';
  isPhoneLoginEnabled: boolean;
  isEmailLoginEnabled: boolean;
  phoneNumber?: string;
  email?: string;
};

const SignInForm: React.FC<Props> = ({
  creatorSlug,
  initialAuthMethod = 'email',
  onChangeView,
  isPhoneLoginEnabled: isPhoneLoginEnabledInput,
  isEmailLoginEnabled: isEmailLoginEnabledInput,
  phoneNumber,
  email,
}) => {
  const [isEmailAuth, setIsEmailAuth] = useState<boolean>(initialAuthMethod === 'email');

  const isPhoneLoginEnabled = isPhoneLoginEnabledInput || initialAuthMethod === 'phone';
  const isEmailLoginEnabled = isEmailLoginEnabledInput || initialAuthMethod === 'email';

  useEffect(() => {
    if (isPhoneLoginEnabled && !isEmailLoginEnabled) {
      setIsEmailAuth(false);
    } else if (!isPhoneLoginEnabled && isEmailLoginEnabled) {
      setIsEmailAuth(true);
    } else if (isPhoneLoginEnabled && isEmailLoginEnabled) {
      setIsEmailAuth(initialAuthMethod !== 'phone');
    } else {
      setIsEmailAuth(true);
    }
  }, [initialAuthMethod, isPhoneLoginEnabled, isEmailLoginEnabled]);

  const handleTapSignUp = (): void => onChangeView('signUp');

  return (
    <fieldset className={authStyles.formwrapper}>
      <legend>Sign In</legend>
      {isEmailLoginEnabled && isPhoneLoginEnabled && (
        <Tabs
          inputName="authType"
          inputs={[
            { id: 'auth-email', label: 'Email' },
            { id: 'auth-phone', label: 'Phone' },
          ]}
          onChange={(val) => setIsEmailAuth(val === 'auth-email')}
        />
      )}
      {isEmailAuth
        ? <SignInWithEmail onChangeView={onChangeView} creatorSlug={creatorSlug} email={email} />
        : <SignInWithPhone onChangeView={onChangeView} creatorSlug={creatorSlug} phoneNumber={phoneNumber}/>
      }
      <TermsAndPolicy isSignIn />
      <small>
        Don&apos;t have an account?{' '}
        <Button isWrapper onClick={handleTapSignUp}>
          Sign up
        </Button>
        .
      </small>
    </fieldset>
  );
};

export default SignInForm;
