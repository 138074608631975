import { useEffect } from 'react';
import CustomSelect from '@components/FormFields/CustomSelect';
import SUPPORTED_COUNTRIES from './countryList';

type Props = {
  onChange: (newValue: string) => void;
  value: string;
};

const OPTIONS: Parameters<typeof CustomSelect>[0]['options'] = SUPPORTED_COUNTRIES.map((i) => ({
  ...i,
  key: i.value,
  title: i.label,
}));
const VALID_VALUES = OPTIONS.map(option => option.value);
const DEFAULT_COUNTRY = OPTIONS.find(option => option.value === 'US') || OPTIONS[0];

const CountrySelect: React.FC<Props> = ({ onChange, value }) => {
  useEffect(() => {
    if (!VALID_VALUES.includes(value)) {
      onChange(DEFAULT_COUNTRY.value);
    }
  }, [value, onChange]);
  return (
    <CustomSelect
      id='country'
      label='Country'
      value={value}
      options={OPTIONS}
      onChange={onChange}
      withFilter
    />
  );
};

// eslint-disable-next-line import/no-unused-modules
export default CountrySelect;
export { DEFAULT_COUNTRY };
