import { useState, useEffect } from 'react';
import useNoodleApi from '@hooks/useNoodleApi';
import * as tsClient from '@tsClient';
import TextField from '@components/FormFields/TextInput';
import PhoneInput from '@components/FormFields/PhoneInput';
import EmailInput from '@components/FormFields/EmailInput';
import PasswordInput from '@components/FormFields/PasswordInput';
import Button from '@components/Buttons';
import FieldError from '@components/FieldError';
import { useUserProfile, useUser } from '@providers/Auth';
import { mixpanelAlias, mixpanelTrack } from '@providers/Mixpanel';
import { logError } from '@providers/ErrorTracking';
import generateExistingUserErrorMessage from '@helpers/generateExistingUserErrorMessage';
import authStyles from '../Auth.module.scss';

type Props = {
  onSuccess?: () => void;
};

const AccountCompletionForm: React.FC<Props> = ({ onSuccess }) => {
  const [_user, _setUser, setUserByToken] = useUser();
  const profile = useUserProfile();
  const [name, setName] = useState('');
  const [nameError, setNameError] = useState<string | null>(null);
  const [phoneNumber, setPhoneNumber] = useState('');
  const [phoneNumberError, setPhoneNumberError] = useState<string | null>(null);
  const [email, setEmail] = useState('');
  const [emailError, setEmailError] = useState<string | null>(null);
  const [password, setPassword] = useState('');
  const [passwordError, setPasswordError] = useState<string | null>(null);
  const [submitError, setSubmitError] = useState<string | null>(null);
  const [isSubmitted, setIsSubmitted] = useState(false);

  const { fetchingState: completeAccountState, getData: completeAccount } = useNoodleApi(tsClient.auth.completeAccount, { toastOnError: true });

  useEffect(() => {
    if (profile) {
      setPhoneNumber(profile.phoneNumber || '');
      setEmail(profile.email || '');
      setName(profile.name || '');
    }
  }, [profile]);

  const handleUpdate: React.FormEventHandler<HTMLFormElement> = async (event) => {
    if (event && event.preventDefault) {
      event.preventDefault();
      event.stopPropagation();
    }
    setIsSubmitted(true);
    setSubmitError(null);
    if (
      nameError
      || phoneNumberError
      || emailError
      || passwordError
    ) {
      setSubmitError('Please fill in all of the fields');
      return;
    }

    const response = await completeAccount({
      email,
      name,
      password,
      phoneNumber: phoneNumber || null,
    });

    if (response.data) {
      const { token, isExistingUser } = response.data;
      const user = setUserByToken(token);
      if (user) {
        mixpanelTrack('Logged In', {
          creationMethod: 'Account Completion',
          email,
          phoneNumber,
        });
        if (!isExistingUser) {
          mixpanelAlias(user?.id);
        }
      } else {
        logError(new Error('Invalid token returned'));
      }
      if (onSuccess) {
        onSuccess();
      }
    } else if (response.error) {
      if (response.error.type === 'ExistingUserError') {
        setSubmitError(generateExistingUserErrorMessage(response.error));
      } else {
        setSubmitError(response.error.message);
      }
    }
  };

  const handlePasswordError: Parameters<typeof PasswordInput>[0]['onError'] = (err) => {
    setPasswordError(err.message);
  };

  if (!profile) {
    return null;
  }

  return (
    <form onSubmit={handleUpdate}>
      <fieldset className={authStyles.formwrapper}>
        <p className="heading-sm">Finish setting up your account</p>
        <p className="body-sm">You haven’t yet set up your email and password. Do so now to access your purchases.</p>
        <TextField
          id="name"
          label="Name"
          value={name}
          placeholder="Enter your name"
          onChange={setName}
          onError={setNameError}
          autoComplete={'name'}
          isTouched={isSubmitted}
          required
        />
        <PhoneInput
          id="phone"
          value={phoneNumber}
          label={'Phone number'}
          placeholder="Enter your phone number (optional)"
          onChange={setPhoneNumber}
          onError={setPhoneNumberError}
          error={phoneNumberError}
          isTouched={isSubmitted}
        />
        <EmailInput
          id="email"
          value={email}
          onChange={setEmail}
          onError={setEmailError}
          label="Email"
          placeholder="Enter your email"
          required
          isTouched={isSubmitted}
          error={emailError}
        />
        <PasswordInput
          id="password"
          value={password}
          onChange={setPassword}
          onError={handlePasswordError}
          label="Password"
          placeholder="Enter your password"
          checkStrength
          isTouched={isSubmitted}
          error={passwordError}
          required
        />

        {submitError && <FieldError>Error: {submitError}</FieldError>}
        <Button isSecondary type="submit" isShimmering={completeAccountState.isFetching}>
          Save
        </Button>

      </fieldset>
    </form>
  );
};

export default AccountCompletionForm;
