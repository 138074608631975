import ApiError from "@helpers/ApiError";

const generateExistingUserErrorMessage = (error: ApiError | null): string | null => {
  if (!error) {
    return null;
  }

  if (!error.errors) {
    return error ? error.message : null;
  }

  if (error.errors.length > 1) {
    return 'Multiple unique fields already in use. Contact support@noodle.shop if you\'d like to merge your accounts.';
  }

  if (error.errors.length === 1) {
    if (error.errors[0].toLocaleLowerCase().includes('email')) {
      return 'Email already in use. Contact support@noodle.shop if you\'d like to merge your accounts.';
    }
    if (error.errors[0].toLocaleLowerCase().includes('phone')) {
      return 'Phone number already in use. Contact support@noodle.shop if you\'d like to merge your accounts.';
    }
  }

  return error ? error.message : null;
};

export default generateExistingUserErrorMessage;