import { useContext, useEffect, useState } from 'react';
import clsx from 'classnames';
import Button from '@components/DesignLibrary/Button';
import PasscodeInput from '@components/FormFields/PasscodeInput';
import Spacer from '@components/Spacer';
import { useUser } from '@hooks';
import TeamsContext from '@providers/Teams/TeamsContext';
import { logError } from '@providers/ErrorTracking';
import DocumentAccessContext from './Context';
import s from './ForbiddenToCreator.module.scss';

type Props = {
  className?: string;
  size?: 'sm' | 'lg';
  isCenteredVertically?: boolean;
  onSubmit?: (t?: string) => void;
};

const ForbiddenToCreator: React.FC<Props> = ({ className, size, isCenteredVertically, onSubmit }) => {
  const { creatorId } = useContext(TeamsContext);
  const { getAccessToken, isInContext } = useContext(DocumentAccessContext);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [user] = useUser();
  const [password, setPassword] = useState('');

  useEffect(() => {
    if (!isInContext) {
      const error = new Error('Using ForbiddenToCreator but not in DocumentAccessProvider');
      console.error(error);
      logError(error);
    }
  }, [isInContext]);

  const handleChangePassword = (str: string): void => {
    setPassword(str);
  };

  const handleSubmit = async (): Promise<void> => {
    if (!isInContext) {
      console.error('Trying to submit access token, but can not.');
    }

    if (creatorId && user?.id) {
      setIsSubmitting(true);
      const { data } = await getAccessToken({
        creatorId,
        password,
        personId: user?.id,
      });
      setIsSubmitting(false);
      onSubmit?.(data?.accessToken);
    }
  };

  if (!isInContext) {
    return null;
  }

  if (size === 'sm') {
    return (
      <div className={clsx(isCenteredVertically && s['center-vertical'], s['container-small'], className)}>
        <div>
          <PasscodeInput
            id={'document-access-password'}
            size={size}
            onChange={handleChangePassword}
            label={'Enter passcode to access this document'}
          />
          <Button
            variant='secondary'
            onClick={handleSubmit}
            loading={isSubmitting}
            size='sm'
          >
            Submit
          </Button>
        </div>
      </div>
    );
  }

  return (
    <div className={clsx(isCenteredVertically && s['center-vertical'], className)}>
      <div>
        <PasscodeInput
          id={'document-access-password'}
          onChange={handleChangePassword}
          label={'Enter passcode to access this document'}
        />
        <Spacer />
        <Button
          variant='primary'
          size='md'
          fullWidth
          onClick={handleSubmit}
          loading={isSubmitting}
        >
          Submit
        </Button>
      </div>
    </div>
  );
};

export default ForbiddenToCreator;
