import classNames from 'classnames';
import s from './CheckBox.module.scss';
import VisualBox from '../shared/VisualBox';

type Props = {
  className?: string;
  id?: string;
  isChecked: boolean;
  label: string;
  onChange: (newIsChecked: boolean) => void;
  size?: 'sm' | 'md';
  style?: Parameters<typeof VisualBox>[0]['style'];
  disabled?: boolean;
};

const CheckBox: React.FC<Props> = ({
  className,
  id,
  isChecked,
  label,
  onChange,
  size = 'md',
  style = 'square',
  children,
  disabled,
}) => (
  <>
    <label className={classNames(s.container, size === 'md' && s.md, size === 'sm' && s.sm, className)}>
      <input
        type="checkbox"
        id={id}
        checked={isChecked}
        onChange={event => onChange(event.target.checked)}
        disabled={disabled}
      />
      <VisualBox isChecked={isChecked} size={size} style={style} />
      {label}
      {children}
    </label>
  </>
);

export default CheckBox;
