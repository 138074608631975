import { ReactElement } from 'react';
import Card from 'components/Card';
import { Asset, Creator, NoodleProductTypes } from '@typings/graphql-models';
import ConnectWithCreatorTitle from '@components/ConnectWithCreatorTitle';
import PoweredByNoodle from '@components/PoweredByNoodle';
import Image from '@components/Image';
import BackButton from '@components/BackButton';
import isInPopoverWidget from '@helpers/isInPopoverWidget';
import s from './ConnectWithCreatorWrapper.module.scss';

type ThisCreator = Pick<Creator, 'slug' | 'name'> & {
  person?: {
    image?: Pick<Asset, 'url'> | null;
  } | null;
  primaryColour?: {
    hex: string;
  } | null;
};

type Props = {
  creator?: ThisCreator | null;
  children?: ReactElement;
  productIcon?: NoodleProductTypes;
};

const ConnectWithCreatorWrapper = ({ creator, children, productIcon }: Props): ReactElement => (
  <div className={s.wrapper__container}>
    {creator?.person?.image?.url && <Image image={creator?.person?.image?.url} alt="" className={s.wrapper__image} />}
    <div className={s.wrapper__content}>
      <BackButton className={s.back} />
      <div style={{ minHeight: 48 }} />
      <ConnectWithCreatorTitle creatorName={creator?.name} creatorImageUrl={creator?.person?.image?.url} productIcon={productIcon} />
      <Card className={s.wrapper__form} style={(!creator?.person?.image?.url && { border: '1px solid var(--color-gray-25' }) || {}}>
        {children}
        {!isInPopoverWidget() && <PoweredByNoodle style={{ marginTop: 16 }} />}
      </Card>
      <div className={s.wrapper__spacer} />
    </div>
  </div>
);

export default ConnectWithCreatorWrapper;
