import classnames from 'classnames';
import Info from '@components/Icons/Info';
import s from './BannerStatus.module.scss';
import Button from '../Button';

const BannerStatus: React.FC<{
  status: 'success' | 'error' | 'warning';
  label: string;
  indent?: boolean;
  className?: string;
  cta?: {
    label: string;
    onClick: () => void;
  }
}> = ({ className, indent, status, label, cta }) => (
  <div
    className={classnames(
      indent && s.bannerIndent,
      status === 'error' && s.bannerError,
      status === 'warning' && s.bannerWarning,
      status === 'success' && s.bannerSuccess,
      className,
    )}
  >
    <Info
      size={16}
      weight="fill"
      color={
        (status === 'error' && 'var(--color-error')
          || (status === 'warning' && 'var(--color-warning')
          || 'var(--color-success)'
      }
    />
    <span>{label}</span>
    {cta && <Button variant="secondary" size="sm" onClick={cta.onClick}>{cta.label}</Button>}
  </div>
);

export default BannerStatus;
