import { getCreatorProducts, getRoutableCompany } from '@tsClient';
import getCreatorProfile from '@tsClient/creators/getCreatorProfile';
import getRecentlyUsedPrices from '@tsClient/creators/getRecentlyUsedPrices';
import getCreatorCollections from '@tsClient/getCreatorCollections';
import { noop } from 'lodash';
import { ReactNode, createContext } from 'react';

type RoutableCompany = Awaited<ReturnType<typeof getRoutableCompany>>;
type Creator = Awaited<ReturnType<typeof getCreatorProfile>>;
type Collections = Awaited<ReturnType<typeof getCreatorCollections>>[0]['productTypes'];
type Product = Awaited<ReturnType<typeof getCreatorProducts>>['products'][0];
type Price = Awaited<ReturnType<typeof getRecentlyUsedPrices>>[0];

type DashboardContextState = {
  backLink: string | null;
  backFallbackLink: string | null;
  fetchPrices: () => Promise<unknown>;
  fetchProducts: () => Promise<unknown>;
  fetchCollections: () => Promise<unknown>;
  creator: Creator | null;
  collections: Collections | null;
  prices: Price[] | null;
  products: Product[] | null;
  setTitle: (title: string | null) => void;
  isInDashboard: boolean;
  isPaymentModalOpen: boolean;
  isTeamsModalOpen: boolean;
  isUserModalOpen: boolean;
  isShareProductModalOpen: string;
  isCreateServiceModalOpen: boolean;
  isInitiateBookingModalOpen: boolean;
  triggerRefetchBooking: () => void;
  refetchBookingsRefreshKey: number;
  topContent: ReactNode;
  title: string | null;
  setIsTFAModalOpen: (isOpen: boolean) => void;
  setAppModalOpen: (isOpen: boolean) => void;
  setBrowserTabTitle: (title: string | null) => void;
  setPaymentModalOpen: (isOpen: boolean) => void;
  setTeamsModalOpen: (isOpen: boolean) => void;
  setIsUserModalOpen: (isOpen: boolean) => void;
  setTopContent: (content: ReactNode) => void;
  setTitleContent: (content: ReactNode) => void;
  setBackLink: (link: string | null) => void;
  setBackFallbackLink: (link: string | null) => void;
  routableCompany: RoutableCompany | null;
  refetchCreator: () => Promise<Creator | null>;
  refetchUnreadInboxItemCount: () => Promise<void>;
  setNotificationModalOpen: (isOpen: boolean) => void;
  setWidgetSnippetModalOpen: (isOpen: boolean) => void;
  setDocumentRequestsPasscodeModalOpen: (isOpen: boolean) => void;
  setIsCreateServiceModalOpen: (isOpen: boolean) => void;
  setIsShareProductModalOpen: (productSlug: string) => void;
  setInitiateBookingModalOpen: (isOpen: boolean) => void;
  setInitiateCaseModalOpen: (isOpen: boolean) => void;
};

const DashboardContext = createContext<DashboardContextState>({
  backFallbackLink: null,
  backLink: null,
  collections: null,
  creator: null,
  fetchCollections: async () => { /* empty */ },
  fetchPrices: async () => { /* empty */ },
  fetchProducts: async () => { /* empty */ },
  isCreateServiceModalOpen: false,
  isInDashboard: false,
  isInitiateBookingModalOpen: false,
  isPaymentModalOpen: false,
  isShareProductModalOpen: '',
  isTeamsModalOpen: false,
  isUserModalOpen: false,
  prices: null,
  products: null,
  refetchBookingsRefreshKey: 0,
  refetchCreator: async () => null,
  refetchUnreadInboxItemCount: async () => { /* empty */ },
  routableCompany: null,
  setAppModalOpen: () => { /* empty */ },
  setBackFallbackLink: () => { /* empty */ },
  setBackLink: () => { /* empty */ },
  setBrowserTabTitle: () => { /* empty */ },
  setDocumentRequestsPasscodeModalOpen: () => { /* empty */ },
  setInitiateBookingModalOpen: () => { /* empty */ },
  setInitiateCaseModalOpen: () => { /* empty */ },
  setIsCreateServiceModalOpen: () => { /* empty */ },
  setIsShareProductModalOpen: () => { /* empty */ },
  setIsTFAModalOpen: () => { /* empty */ },
  setIsUserModalOpen: () => { /* empty */ },
  setNotificationModalOpen: () => { /* empty */ },
  setPaymentModalOpen: () => { /* empty */ },
  setTeamsModalOpen: () => { /* empty */ },
  setTitle: () => { /* empty */ },
  setTitleContent: () => { /* empty */ },
  setTopContent: () => { /* empty */ },
  setWidgetSnippetModalOpen: () => { /* empty */ },
  title: null,
  topContent: null,
  triggerRefetchBooking: noop,
});

export default DashboardContext;
