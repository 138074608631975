import { FC, ReactElement } from 'react';
import Header from '@components/DesignLibrary/Header';
import PageContext from '@components/DesignLibrary/PageContext';
import { useIsMobile } from '@hooks';
import { SIZES } from '@styles/media';
import Info from '@components/Icons/Info';
import CaretCircleDown from '@components/Icons/CaretCircleDown';
import {m} from 'framer-motion';
import LayoutRightPanel from './LayoutRightPanel';
import s from './MainPanelSubLayout.module.scss';

type Props = {
  header: Pick<Parameters<typeof Header>[0], 'title' | 'description' | 'secondaryActions' | 'actions' | 'actionsComponent' | 'topRightComponent'>;
  pageContext: Parameters<typeof PageContext>[0];
  rightPanel?: {
    title: string;
    component: ReactElement;
  };
};

const MainPanelSubLayout: FC<Props> = ({ children, header, pageContext, rightPanel }) => {
  const isMobile = useIsMobile(SIZES.xl);
  return (
    <m.div initial={{opacity:0}} animate={{opacity:1}} className={s.container}>
      <Header
        {...header}
      />
      <PageContext
        {...pageContext}
      />
      <div className={s.pageContent} style={{ flexDirection: isMobile ? 'column' : 'row' }}>
        {rightPanel && isMobile && (
          <details className={s.details}>
            <summary>
              <div>
                <Info size={16} />
                <h2>{rightPanel.title}</h2>
              </div>
              <CaretCircleDown size={16} weight="fill" />
            </summary>
            {rightPanel.component}
          </details>
        )}
        <div className={s.mainPanel}>
          {children}
        </div>
        {rightPanel && !isMobile && (
          <LayoutRightPanel>
            {rightPanel.component}
          </LayoutRightPanel>
        )}
      </div>
    </m.div>
  );
};

export default MainPanelSubLayout;
