import MimeTypes from '@typings/MimeTypes';

import File from './File';
import PDFIcon from './PDFIcon';
import Cookie from './Cookie';
import FileDashed from './FileDashed';
import ImageIcon from './Image';

type Props = {
  mimeType?: string;
  size?: number;
  isEmpty: boolean;
};

const FileType: React.FC<Props> = ({ mimeType, size, isEmpty }) => {
  if (mimeType) {
    if (mimeType === MimeTypes.PDF) {
      return <PDFIcon size={size} weight='fill' />;
    }

    if (mimeType === MimeTypes.AVI) {
      return <Cookie size={size} weight='fill' />;
    }

    if (mimeType.includes('image')) {
      return <ImageIcon size={size} weight='fill' />;
    }
  }

  if (isEmpty) {
    return <FileDashed size={size} />;
  }

  return <File size={size} weight='fill' />;
};

export default FileType;