import { useEffect, useState, useContext } from 'react';
import { useRouter } from 'next/router';
import deserializeQueryStringItem from '@helpers/deserializeQueryStringItem';
import { isInIframe } from '@helpers/helper';
import TeamsContext from '@providers/Teams/TeamsContext';

type Props = {
  creator?: {
    id: string;
  } | null;
};

const useCanEditNoodle = ({ creator }: Props): boolean => {
  const [canEdit, setCanEdit] = useState(false);

  const { creatorId } = useContext(TeamsContext);
  const router = useRouter();

  useEffect(() => {
    const isCustomerView = deserializeQueryStringItem(router.query.customerView, { type: 'boolean' });
    const canEditNoodle = Boolean(creatorId === creator?.id) && !isInIframe() && !isCustomerView;

    setCanEdit(canEditNoodle);
  }, [creator?.id, creatorId, router.query]);

  return canEdit;
};

export default useCanEditNoodle;
