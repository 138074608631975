import { useMemo } from "react";
import { IBM_Plex_Mono as ibmPlexMono } from 'next/font/google';
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';

const mono = ibmPlexMono({
  subsets: ['latin'],
  weight: '400',
});

type PrettyJsonProps = {
  stringifiedJson: string
};
const PrettyJson: React.FC<PrettyJsonProps> = ({ stringifiedJson }) => {
  const prettifiedJson = useMemo(() => {
    let retVal = stringifiedJson;

    try {
      retVal = JSON.stringify(JSON.parse(stringifiedJson), null, 2);
    } catch {
      // When the response isn't proper json
      retVal = stringifiedJson;
    }

    return retVal;
  }, [stringifiedJson]);

  return (
    <div
      style={{
        border: '1px solid var(--color-gray-25)',
        borderRadius: 4,
      }}
    >
      <SyntaxHighlighter
        language="json"
        customStyle={{
          backgroundColor: 'var(--color-gray-0)',
          borderRadius: 4,
          fontSize: 14,
          margin: 0,
        }}
        wrapLongLines
        codeTagProps={{ style: mono.style }}
      >{prettifiedJson}</SyntaxHighlighter>
    </div>
  );
};

export default PrettyJson;
