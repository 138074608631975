import { FC } from 'react';
import CustomLink from '@components/CustomLink';
import type { Icon } from '@phosphor-icons/react';
import s from './TabText.module.scss';

type Props = {
  label: string;
  selected: boolean;
  icon?: Icon;
} & (
  | {
      href: string;
      onClick?: never;
    }
  | {
      href?: never;
      onClick: () => unknown;
    }
);

const TabText: FC<Props> = ({ label, icon: IconComponent, selected, href, onClick }) => {
  const Tag = href ? CustomLink : 'button';
  return (
    <Tag className={s.container} style={selected ? { background: 'var(--color-gray-25)' } : {}} {...(href ? { to: href } : { onClick })}>
      {IconComponent && <IconComponent size={12} weight='fill' color='var(--color-primary)' />}
      {label}
    </Tag>
  );
};

export default TabText;
