import { useCallback, useEffect, useRef, useState } from 'react';
import InputField from '../InputField';
import validate from './validate';

type Props = {
  id: string;
  value: string;
  placeholder?: string;
  onChange: (newValue: string) => void;
  onError?: (error: string | null) => void;
  required?: boolean;
  label: string;
  error?: string | null;
  isTouched?: boolean;
  disabled?: boolean;
};

const EmailInput: React.FC<Props> = ({
  id,
  placeholder = 'email',
  value,
  onChange,
  onError,
  required = false,
  label = 'Email',
  error,
  isTouched,
  disabled,
}) => {
  const [validationError, setValidationError] = useState<string | null>(null);

  // Want to always use the latest without needing to put in useCallback/useEffect dependency lists
  const onChangeRef = useRef<Props['onChange']>();
  onChangeRef.current = onChange;
  const onErrorRef = useRef<Exclude<Props['onError'], undefined> | null>();
  onErrorRef.current = onError;

  useEffect(() => {
    const lcValue = value.toLowerCase().replace(/\s+/g, '');
    if (lcValue !== value && onChangeRef.current) {
      onChangeRef.current(lcValue);
    }
    const newError = validate(lcValue, { isRequired: required });
    setValidationError(newError);
    if (onErrorRef.current) {
      onErrorRef.current(newError);
    }
  }, [value, required]);

  const handleChange = useCallback((newValue: string): void => {
    if (onChangeRef.current) {
      onChangeRef.current(newValue.toLowerCase().replace(/\s+/g, ''));
    }
  }, []);

  const name = id;

  return (
    <InputField
      id={id}
      name={name}
      values={{ [name]: value }}
      formErrors={{ [name]: error || validationError }}
      onChange={handleChange}
      placeholder={placeholder}
      type={'email'}
      label={label}
      isTouched={isTouched}
      autoComplete='email'
      disabled={disabled}
      hasFixedHeight={false}
      required={required}
    />
  );
};

export default EmailInput;
