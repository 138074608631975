import ApiError from "@helpers/ApiError";

const getErrorMessage = (error: ApiError): string | null => {
  if (error.type === 'InvalidPhoneNumberError') {
    return 'Invalid number. Make sure your number is correct and that you\'ve selected the proper country from the drop down list.';
  }

  if (error.type === 'IsLandlinePhoneNumberError') {
    return 'Landlines not supported. Please provide another number or contact support@noodle.shop for help.';
  }

  if (error.type === 'RateLimitError') {
    return 'Too many attempts, please try again later.';
  }

  if (error.type === 'OTPRequestError') {
    return error.errors?.[0] || error.message;
  }

  return '__SUPPORT__';
};

export default getErrorMessage;
