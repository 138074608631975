import { useState } from 'react';
import { useRouter } from 'next/router';
import * as tsClient from '@tsClient';
import useNoodleApi from '@hooks/useNoodleApi';
import { getUrl, IDENTIFIERS } from '@helpers/urlsHelper';
import PhoneInput from '@components/FormFields/PhoneInput';
import Button from '@components/DesignLibrary/Button';
import FieldError from '@components/FieldError';
import requestOTPErrorMessage from '../helpers/requestOTPErrorMessage';

type Props = {
  onSuccess: (args: { phoneNumber: string, country: string; recipient: string; canSendViaEmail: boolean }) => void;
  initialPhoneNumber?: string;
};

const RequestOTP: React.FC<Props> = ({ initialPhoneNumber, onSuccess }) => {
  const router = useRouter();
  const [phoneNumber, setPhoneNumber] = useState(initialPhoneNumber || '');
  const [country, setCountry] = useState('');
  const [error, setError] = useState<string | null>(null);

  const {
    getData: requestOTP,
    fetchingState: userAuthorizeState,
  } = useNoodleApi(tsClient.auth.requestOTP, {
    healthMonitor: { name: 'sign-in' },
    reportError: false,
    toastOnError: true,
  });

  const handleRequestOTP: React.FormEventHandler<HTMLFormElement> = async (event) => {
    if (event && event.preventDefault) {
      event.preventDefault();
      event.stopPropagation();
    }
    const response = await requestOTP({ channel: 'sms', phoneNumber });
    if (response.data) {
      onSuccess({
        canSendViaEmail: response.data.canRequestViaEmail,
        country,
        phoneNumber,
        recipient: response.data.recipient,
      });
    } else if (response.error) {
      const msg = requestOTPErrorMessage(response.error);
      if (msg === '__SUPPORT__') {
        router.push(getUrl(IDENTIFIERS.SUPPORT));
      } else {
        setError(msg);
      }
    }
  };

  const handlePhoneInputChange: Parameters<typeof PhoneInput>[0]['onChange'] = (newPhoneNumber, newCountry) => {
    setPhoneNumber(newPhoneNumber);
    setCountry(newCountry);
  };

  return (
    <form onSubmit={handleRequestOTP}>
      <PhoneInput
        id='phoneNumber'
        value={phoneNumber}
        onChange={handlePhoneInputChange}
        onError={setError}
        label="Phone number"
        required
      />
      {error && <FieldError>Error: {error}</FieldError>}
      <Button
        variant='primary'
        size='lg'
        type="submit"
        loading={userAuthorizeState.isFetching}
        disabled={Boolean(error)}
        fullWidth
      >
      Sign In
      </Button>
    </form>
  );
};

export default RequestOTP;
