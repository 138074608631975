import { useEffect, useState } from 'react';
import EmailInput from '@components/FormFields/EmailInput';
import PasswordInput from '@components/FormFields/PasswordInput';
import Button from '@components/DesignLibrary/Button';
import Buttons from '@components/Buttons';
import { mixpanelAlias, mixpanelTrack } from '@providers/Mixpanel';
import useNoodleApi from '@hooks/useNoodleApi';
import { useUser } from '@hooks';
import * as tsClient from '@tsClient';
import FieldError from '@components/FieldError';
import { useRouter } from 'next/router';
import deserializeQueryStringItem from '@helpers/deserializeQueryStringItem';
import { View } from '../types';

type Props = {
  creatorSlug?: string;
  email?: string;
  onChangeView: (view: View, data: { email: string }) => void;
};

const SignInWithEmail: React.FC<Props> = ({ creatorSlug, email: passedDownEmail, onChangeView }) => {
  const router = useRouter();
  const queryEmail = deserializeQueryStringItem(router.query.email, { type: 'string' });
  const [_user, _setUser, setUserByToken] = useUser();
  const [email, setEmail] = useState(queryEmail || '');
  const [emailError, setEmailError] = useState<string | null>(null);
  const [password, setPassword] = useState('');
  const [isSubmitted, setIsSubmitted] = useState(false);
  const lockEmailField = Boolean(queryEmail || passedDownEmail);

  const {
    data: signInResponse,
    error: signInError,
    fetchingState: signInState,
    getData: passwordSignIn,
  } = useNoodleApi(tsClient.auth.passwordSignIn, {
    healthMonitor: { name: 'sign-in' },
  });

  useEffect(() => {
    if (signInResponse) {
      const { isExistingUser, token } = signInResponse;
      const currentUser = setUserByToken(token);
      mixpanelTrack('Logged In', {
        email,
        method: 'Email and password',
      });
      if (!isExistingUser) {
        mixpanelTrack('Created Account', {
          creationMethod: 'Normal Signup (Login)',
        });
        if (currentUser) {
          mixpanelAlias(currentUser.id);
        }
      }
    }
  // email only in mixpanelTrack, don't execute if it changes
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [signInResponse, setUserByToken]);

  useEffect(() => {
    if (passedDownEmail) {
      setEmail(passedDownEmail);
    } else if (queryEmail) {
      setEmail(queryEmail);
    }
  }, [queryEmail, passedDownEmail]);

  useEffect(() => {
    if (signInError) {
      mixpanelTrack('Sign in with email error', { reason: signInError.message, type: signInError.type || 'Unknown' });
    }
  }, [signInError]);

  const handleForgotPasswordClick = (): void => {
    onChangeView('forgotPassword', { email });
  };

  const handleSignIn: React.FormEventHandler<HTMLFormElement> = async event => {
    if (event && event.preventDefault) {
      event.preventDefault();
      event.stopPropagation();
    }
    setIsSubmitted(true);
    if (emailError) {
      return;
    }

    passwordSignIn({ creatorSlug, identifier: email, password });
  };

  const errorMessage = (signInError?.type === 'LoginFailedError' && (signInError?.data as { reason: string })?.reason === 'Wrong password' && (
    <>
        Wrong password. Try again or click{' '}
      <Buttons
        isWrapper
        type="button"
        onClick={() =>
          onChangeView('forgotPassword', {
            email,
          })
        }
      >
          Forgot password
      </Buttons>{' '}
        to reset it.
    </>
  ))
    || (signInError?.type === 'LoginNoSuchUserError' && (
      <>
        No user found. Try to{' '}
        <Buttons
          isWrapper
          type="button"
          onClick={() =>
            onChangeView('signUp', {
              email,
            })
          }
        >
          Sign up
        </Buttons>{' '}
        instead
      </>
    ))
    || signInError?.message
    || null;

  return (
    <form onSubmit={handleSignIn}>
      <EmailInput
        id="identifier"
        disabled={lockEmailField}
        value={email}
        onChange={setEmail}
        onError={setEmailError}
        label="Email"
        placeholder="Enter your email"
        required
        isTouched={isSubmitted}
      />
      <PasswordInput id="password" value={password} onChange={setPassword} onForgotPasswordClick={handleForgotPasswordClick} label="Password" />
      {errorMessage && <FieldError>{errorMessage}</FieldError>}
      <Button type='submit' size='lg' variant='primary' loading={signInState.isFetching} fullWidth disabled={
        (emailError !== null)
            || signInState.isFetching
      }>
        Sign In
      </Button>
    </form>
  );
};

export default SignInWithEmail;
