import { useCallback, useContext, useEffect, useMemo } from 'react';
import jwtDecode from 'jwt-decode';
import useNoodleApi from '@hooks/useNoodleApi';
import * as tsClient from '@tsClient';
import { mixpanelTrack } from '@providers/Mixpanel';
import { serializers, useLocalStorageState } from '@providers/LocalStorageState';
import { logError } from '@providers/ErrorTracking';
import TeamsContext from '@providers/Teams/TeamsContext';
import { usePrevious } from '@hooks';
import useToast, { ToastTypeVariants } from '@hooks/useToast';
import DocumentAccessContext from './Context';
import { DecodedAccessToken } from './typings';

const getTimeTillExpiration = (accessToken: string): number => {
  try {
    const decoded = jwtDecode(accessToken) as DecodedAccessToken;
    if (!decoded.exp) {
      return -1;
    }
    return 1000 * (decoded.exp - Math.floor(Date.now()/1000) - 60);
  } catch(error) {
    logError(error);
    return -1;
  }
};

const isValidToken = (accessToken: string | null): boolean => {
  if (!accessToken) {
    return false;
  }
  const deltaT = getTimeTillExpiration(accessToken);
  const isValid = deltaT > 0;
  mixpanelTrack('document-access-token - checking validity', { deltaT, isValid });
  return isValid;
};

const DocumentAccessProvider: React.FC = ({ children }) => {
  const { creatorId } = useContext(TeamsContext);
  const previousCreatorId = usePrevious(creatorId);

  const addToast = useToast();
  const [accessToken, setAccessToken] = useLocalStorageState<string | null>({
    defaultValue: null,
    key: 'accessToken',
    prefix: 'document-access-provider',
    ...serializers.string,
  });

  const { getData: getAccessTokenFromServer } = useNoodleApi(tsClient.documentAccess.getDocumentAccessToken, {
    toastOnError: true,
  });

  const getAccessToken = useCallback(async (body: { creatorId: string; personId: string; password: string }) => {
    const response = await getAccessTokenFromServer(body);
    if (response.data) {
      mixpanelTrack('document-access-token - new', {
        creatorId: body.creatorId,
        personId: body.personId,
      });
      setAccessToken(response.data.accessToken);
    } else {
      addToast(ToastTypeVariants.ERROR, 'Failed to get access token');
      setAccessToken(null);
    }
    return response;
  }, [addToast, setAccessToken, getAccessTokenFromServer]);

  useEffect(() => {
    if (accessToken) {
      const timeTillExpiration = getTimeTillExpiration(accessToken);
      if (timeTillExpiration > 0) {
        // @todo - refresh the token instead of just expiring it.
        const timeout = setTimeout(() => {
          mixpanelTrack('document-access-token - timed out');
          setAccessToken(null);
        }, timeTillExpiration);
        return () => clearTimeout(timeout);
      }
      setAccessToken(null);
    }
    return (): void => { /* */ };
  }, [accessToken, setAccessToken]);

  useEffect(() => {
    if (previousCreatorId && previousCreatorId !== creatorId) {
      setAccessToken(null); // need this more than in LocalStorageStateProvider so that context value changes.
    }
  }, [creatorId, previousCreatorId]);

  const value = useMemo(() => ({
    accessToken: isValidToken(accessToken) ? accessToken : null,
    getAccessToken,
    isInContext: true,
  }), [
    accessToken,
    getAccessToken,
  ]);

  return (
    <DocumentAccessContext.Provider value={value}>
      {children}
    </DocumentAccessContext.Provider>
  );
};

export default DocumentAccessProvider;
