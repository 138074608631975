import CustomLink from '@components/CustomLink';

type BreadcrumbType = {
  href: string;
  label: string;
};

const Breadcrumb: React.FC<{ breadcrumb: BreadcrumbType; isLast: boolean }> = ({ breadcrumb, isLast }) => (
  <>
    <CustomLink to={breadcrumb.href}>{breadcrumb.label}</CustomLink>
    {isLast ? '' : '/'}
  </>
);

export default Breadcrumb;