export enum AssetType {
  Audio = 'audio',
  Video = 'video',
  Links = 'links',
}

export enum CardType {
  Plan = 'plan',
  Broadcast = 'broadcast',
}

export enum ProductType {
  Chat = 'chat',
  Plans = 'plans',
  LiveChat = 'liveChat',
  InPersonChat = 'inPersonChat',
}
