import { useEffect, useState } from 'react';
import { useUser, useIsUserInitialized } from '@providers/Auth';
import ForgotPasswordModal from '@modals/ForgotPasswordModal';
import SignUpForm from '../SignUpForm/SignUpForm';
import SignInForm from '../SignInForm/SignInForm';
import { View } from '../types';
import getLoginsEnabled from '../helpers/getLoginsEnabled';

type Props = {
  creatorSlug?: string;
  defaultView?: View | null;
  initialSignInMethod?: 'phone' | 'email';
  phoneNumber?: string;
  email?: string;
  name?: string;
};

const EmbeddedAuthForm: React.FC<Props> = ({
  creatorSlug,
  defaultView,
  initialSignInMethod,
  phoneNumber,
  email,
  name,
}) => {
  const [view, setView] = useState<View>(defaultView || 'signIn');
  const [user] = useUser();
  const isUserInitialized = useIsUserInitialized();

  const [isForgotPasswordModalOpen, setForgotPasswordModalOpen] = useState(false);
  const [userEmail, setUserEmail] = useState('');

  useEffect(() => {
    if (view === 'forgotPassword') {
      setForgotPasswordModalOpen(true);
      setView('signIn');
    }
  }, [view]);

  const handleChangeView = (newView: View, data?: { email : string }): void => {
    if (newView === 'forgotPassword') {
      setForgotPasswordModalOpen(true);
      setView('signIn');
      setUserEmail(data?.email || '');
    } else {
      setForgotPasswordModalOpen(false);
      setView(newView);
    }
  };

  if (!isUserInitialized) {
    return null;
  }

  if (user && !user.isAnonymous) {
    // should never get here...
    return null;
  }

  if (view === 'signUp') {
    return (
      <SignUpForm
        activationEmail={email}
        activationName={name}
        creatorSlug={creatorSlug}
        onChangeView={handleChangeView}
      />
    );
  }

  if (view === 'signIn') {
    const { isEmailLoginEnabled, isPhoneLoginEnabled } = getLoginsEnabled({ creatorSlug });
    return (
      <>
        {isForgotPasswordModalOpen && (
          <ForgotPasswordModal
            creatorSlug={creatorSlug}
            onChangeView={handleChangeView}
            onClose={() => setForgotPasswordModalOpen(false)}
            userEmail={userEmail}
          />
        )}
        <SignInForm
          creatorSlug={creatorSlug}
          onChangeView={handleChangeView}
          isEmailLoginEnabled={isEmailLoginEnabled}
          isPhoneLoginEnabled={isPhoneLoginEnabled}
          initialAuthMethod={initialSignInMethod}
          phoneNumber={phoneNumber}
          email={email}
        />
      </>
    );
  }

  // should never get here
  return null;
};

export default EmbeddedAuthForm;
export type { View };
