import { FC, useEffect, useRef, useState } from 'react';
import { useIsMobile } from '@hooks';
import { SIZES } from '@styles/media';
import Button from '@components/DesignLibrary/Button';
import Modal from '@components/Modal';
import Spacer from '@components/Spacer';
import ChatsCircle from '@components/Icons/ChatsCircle';
import classNames from 'classnames';
import { useQueryState } from 'nuqs';
import { m } from 'framer-motion';
import debounce from 'lodash/debounce';
import s from './LayoutRightPanel.module.scss';

const LayoutRightPanel: FC<{ isInUserProfile?: boolean, isAttorney?: boolean }> = ({ children, isInUserProfile, isAttorney }) => {
  const [distance, setDistance] = useState(128);
  const [discussionOpen, setDiscussionOpen] = useState(false);
  const [commentIdQuery, setCommentIdQuery] = useQueryState('commentId');
  const containerRef = useRef<HTMLDivElement | null>(null);

  const isMobile = useIsMobile(isInUserProfile ? SIZES.xl : SIZES.lg);

  const updateScrollTopDistance = (): void => {
    const containerTop = containerRef.current?.getBoundingClientRect().top;
    if (containerTop) {
      setDistance(containerTop);
    }
  };

  const debouncedHandleResize = debounce(() => {
    updateScrollTopDistance();
  }, 100);

  const debouncedHandleScroll = debounce(() => {
    updateScrollTopDistance();
  }, 100);

  useEffect(() => {
    window.addEventListener('resize', debouncedHandleResize);
    window.addEventListener('scroll', debouncedHandleScroll);

    return () => {
      window.removeEventListener('resize', debouncedHandleResize);
      window.removeEventListener('scroll', debouncedHandleScroll);
    };
  }, [debouncedHandleResize, debouncedHandleScroll]);

  useEffect(() => {
    if (!isMobile) {
      updateScrollTopDistance();
    }
  }, [distance, isMobile]);

  useEffect(() => {
    if (isInUserProfile && isMobile && commentIdQuery) {
      setDiscussionOpen(true);
    }
  }, [isInUserProfile, isMobile, commentIdQuery]);

  return (
    <>
      {isInUserProfile && isMobile
        ? (
          <>
            <Button onClick={() => setDiscussionOpen(true)} size="sm" variant="primary">
              <ChatsCircle size={16} weight='fill' style={{ marginRight: 8 }} />
              {isAttorney ? 'Chat with your legal team' : 'Open Workflow Discussion'}
            </Button>
            {discussionOpen && (
              <Modal title="Discussion" onClose={() => {
                setDiscussionOpen(false);
                setCommentIdQuery(null);
              }} >
                <Spacer />
                {children}
              </Modal>
            )}
          </>
        )
        : (
          <m.div
            className={classNames(s.container, isInUserProfile && s.isInUserProfile)}
            ref={containerRef}
            style={
              isMobile
                ? { position: 'relative' }
                : undefined
            }
            animate={!isMobile ? { height: `calc(min(100vh, 100dvh) - ${distance < 56 ? 32 : distance}px${isInUserProfile ? ' - 56px' : ''})` } : undefined}
            transition={{ duration: 0.3 }}
            id="rightPanel"
          >
            {children}
          </m.div>
        )}
    </>
  );
};

export default LayoutRightPanel;
