import Buttons from '@components/Buttons';
import InputField from '@components/FormFields/InputField';
import { useToast } from '@hooks';
import { useState } from 'react';
import { ToastTypeVariants } from '@context/ToastContext';
import useNoodleApi from '@hooks/useNoodleApi';
import requestPasswordReset from '@tsClient/auth/requestPasswordReset';
import { useRouter } from 'next/router';
import { View } from '../types';
import s from '../Auth.module.scss';

type Props = {
  onChangeView: (view: View) => void;
  userEmail: string;
  creatorSlug?: string;
};

type Values = {
  email: string;
};

const ForgotPasswordForm: React.FC<Props> = ({ onChangeView, userEmail, creatorSlug }) => {
  const addToast = useToast();
  const router = useRouter();
  const [values, setValues] = useState<Values>({
    email: userEmail,
  });
  const { getData: requestPasswordResetFn, fetchingState: requestingPasswordReset } = useNoodleApi(requestPasswordReset);

  const handleSendResetPasswordEmail = async (): Promise<void> => {
    await requestPasswordResetFn({ creatorSlug, email: values.email, returnTo: router.asPath });
    onChangeView('signIn');
    addToast(ToastTypeVariants.SUCCESS, 'Email with a link to reset your password has been sent.');
  };

  const handleInputChange = (key: string, newValue: string): void => {
    setValues(val => ({ ...val, [key]: newValue }));
  };

  const handleTapSignIn = (): void => onChangeView('signIn');

  return (
    <div className={s.formwrapper}>
      <p className="heading-sm">You are about to reset your password</p>
      <span className="body-sm">We will send a link to reset the password to the email you enter below</span>
      <InputField
        id="email"
        name="email"
        placeholder="Enter email"
        values={values}
        onChange={handleInputChange.bind(null, 'email')}
        hasFixedHeight={false}
        label='Your email'
      />
      <Buttons
        isFetching={requestingPasswordReset.isFetching}
        isFullWidth
        isSecondary
        onClick={handleSendResetPasswordEmail}
      >
      Reset Password
      </Buttons>
      <small>
        Already have an account?{'  '}
        <Buttons isWrapper onClick={handleTapSignIn} disabled={requestingPasswordReset.isFetching}>
          Sign in.
        </Buttons>
      </small>
    </div>
  );
};

export default ForgotPasswordForm;
