import React, { useEffect, useState } from 'react';
import { useRouter } from 'next/router';
import useNoodleApi from '@hooks/useNoodleApi';
import * as tsClient from '@tsClient';
import Button from '@components/DesignLibrary/Button';
import { mixpanelTrack, mixpanelAlias } from '@providers/Mixpanel';
import { getUrl, IDENTIFIERS } from '@helpers/urlsHelper';
import { useUser } from '@providers/Auth';
import useIPGeolocation from '@hooks/useIPGeolocation';
import Shimmer from '@components/Shimmer';
import FieldError from '@components/FieldError';
import InputField from '@components/FormFields/InputField';
import requestOTPErrorMessage from '../helpers/requestOTPErrorMessage';
import s from './VerifyOTP.module.scss';

type Props = {
  authCodeLength: number;
  phoneNumber: string;
  recipient: string;
  country: string;
  creatorSlug?: string;
  canSendViaEmail: boolean;
  source?: string;
};

const VerifyOTP: React.FC<Props> = ({
  authCodeLength,
  phoneNumber,
  recipient,
  country,
  creatorSlug,
  canSendViaEmail,
  source,
}) => {
  const [_user, _setUser, setUserByToken] = useUser();
  const [authCode, setAuthCode] = useState('');
  const [sentTo, setSentTo] = useState(recipient);
  const [error, setError] = useState<string | null>(null);
  const [resendingVia, setResendingVia] = useState<'sms' | 'email' | null>(null);
  const router = useRouter();

  const {
    error: requestOTPError,
    fetchingState: requestOTPState,
    getData: requestOTP,
  } = useNoodleApi(tsClient.auth.requestOTP, {
    healthMonitor: { name: 'sign-in' },
    reportError: false,
  });
  const {
    error: verifyOTPError,
    fetchingState: verifyOTPState,
    getData: verifyOTP,
  } = useNoodleApi(tsClient.auth.verifyOTP, {
    healthMonitor: { name: 'sign-in' },
    reportError: false,
  });

  const [geolocation] = useIPGeolocation();

  const verifyCode = async (code: string): Promise<void> => {
    const codeToSend = code;
    setAuthCode(''); // without this the auto submit keeps firing on errors
    const response = await verifyOTP({
      code: codeToSend,
      country,
      creatorSlug: creatorSlug || undefined,
      ipAddress: geolocation?.ip || null,
      phoneNumber,
      to: phoneNumber,
    });

    if (response.data) {
      const { isExistingUser, token } = response.data;
      const currentUser = setUserByToken(token);
      mixpanelTrack('Logged In', {
        method: resendingVia === 'email' ? 'Email' : 'Phone',
        phoneNumber,
      });
      if (!isExistingUser) {
        mixpanelTrack('Created Account', {
          creationMethod: source || 'Normal Signup',
        });
        if (currentUser) {
          mixpanelAlias(currentUser.id);
        }
      }
    }
  };

  useEffect(() => {
    setSentTo(recipient);
  }, [recipient]);

  const handleResendCode = async (channel: 'sms' | 'email'): Promise<void> => {
    setResendingVia(channel);
    setAuthCode('');
    setError(null);
    const response = await requestOTP({ channel, phoneNumber });

    if (response.data) {
      setSentTo(response.data.recipient);
    } else if (response.error) {
      const msg = requestOTPErrorMessage(response.error);
      if (msg === '__SUPPORT__') {
        router.push(getUrl(IDENTIFIERS.SUPPORT));
      } else {
        setError(msg);
      }
    }

    setResendingVia(null);
  };

  const handleVerifyCode: React.FormEventHandler<HTMLFormElement> = async (event) => {
    if (event && event.preventDefault) {
      event.preventDefault();
      event.stopPropagation();
    }
    if (authCode.length !== authCodeLength) {
      setError('Invalid code');
      return;
    }
    verifyCode(authCode);
  };

  const handleChangeCode = (newCode: string): void => {
    setError(null);
    setAuthCode(newCode.slice(0, authCodeLength));
    if (newCode.length === authCodeLength) {
      verifyCode(newCode);
    }
  };

  const errorMessage = error
    || requestOTPError && (requestOTPError?.errors?.[0] || requestOTPError.message)
    || verifyOTPError?.message
    || null;

  const isBusy = (resendingVia !== null) || requestOTPState.isFetching || verifyOTPState.isFetching;
  const isResendingSMS = resendingVia === 'sms';
  const isResendingEmail = resendingVia === 'email';

  return (
    <form onSubmit={handleVerifyCode}>
      <div className={s['confirm-number__form']}>
        <div className={s.verification}>
          {verifyOTPState.isFetching
            ? (
              <div className={s.shimmer}>
                <Shimmer rounded={false} />
              </div>
            )
            : (
              <InputField
                id="authCode"
                name="authCode"
                placeholder="SMS code"
                onChange={handleChangeCode}
                type="number"
                label={`Please enter the code we sent to ${sentTo}`}
                inputMode='numeric'
              />
            )
          }
          {errorMessage && <FieldError>Error: {errorMessage}</FieldError>}
          <div className={s.resendButtons}>
            <Button loading={isResendingSMS} disabled={isBusy} onClick={() => handleResendCode('sms')} variant='secondary' size='md'>
            Resend code
            </Button>
            {canSendViaEmail && (
              <Button loading={isResendingEmail} disabled={isBusy} onClick={() => handleResendCode('email')} variant='secondary' size='md'>
              Send to Email
              </Button>
            )}
          </div>
          <Button
            size='lg'
            variant='primary'
            type="submit"
            loading={isBusy}
            fullWidth
          >
          Verify code
          </Button>
        </div>
      </div>
    </form>
  );
};

export default VerifyOTP;
