import { createRef, useRef } from 'react';
import s from './PasscodeInput.module.scss';

type Props = {
  id: string;
  onChange: (newValue: string) => void;
  label: string;
  size?: 'sm' | 'lg';
  length?: number;
  required?: boolean;
};

const PasscodeInput: React.FC<Props> = ({
  id,
  onChange,
  label,
  size,
  length = 4,
  required = true,
}) => {

  const onChangeRef = useRef<Props['onChange'] | null>();
  onChangeRef.current = onChange;

  const inputsRef = Array(length).fill(0).map(() => createRef<HTMLInputElement>());

  const focusPrevInput = (index: number): void => {
    if (index > 0) {
      inputsRef[index - 1].current?.focus();
    }
  };

  const focusNextInput = (index: number): void => {
    if (index < inputsRef.length - 1) {
      inputsRef[index + 1].current?.focus();
    }
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>, index: number): void => {
    const cleanedInputValue = e.target.value.replace(/[^\d]/g, '');
    e.target.value = cleanedInputValue;
    if (onChangeRef.current) {
      const passcode = inputsRef.map(input => input.current?.value).join('');
      onChangeRef.current(passcode);
    }
    if (cleanedInputValue.length) {
      focusNextInput(index);
    }
  };

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>, index: number): void => {
    if (e.key === "Backspace" && !e.currentTarget.value) {
      focusPrevInput(index);
    }
  };

  const containerClass = size === 'sm' ? 'inputs-sm' : 'inputs';

  return (
    <label className={s[containerClass]}>
      {label}
      <div>
        {inputsRef.map((inputRef, index) => (
          <input
            name={id}
            key={index}
            type="text"
            inputMode="numeric"
            maxLength={1}
            onChange={e => handleInputChange(e, index)}
            onKeyDown={e => handleKeyDown(e, index)}
            ref={inputRef}
            required={required}
            autoComplete='off'
          />
        ))}
      </div>
    </label>
  );

};

export default PasscodeInput;
