import { m, AnimatePresence } from 'framer-motion';
import React, { ReactElement, FC } from 'react';
import Image from 'next/legacy/image';

import ChatsCircle from '@components/Icons/ChatsCircle';
import Handshake from '@components/Icons/Handshake';
import Globe from '@components/Icons/Globe';
import BookmarkSimple from '@components/Icons/BookmarkSimple';
import UsersThree from '@components/Icons/UsersThree';
import UserImage from '@components/UserImage';
import { NoodleProductTypes } from '@typings/api-models';
import { AssetType, ProductType } from '@lib/UserProfile/types';
import CalendarBlank from '@components/Icons/CalendarBlank';

import classNames from 'classnames';
import s from './ProfileProductIcons.module.scss';

type IconsProps = {
  isSmall?: boolean;
  isOnCreatorPage?: boolean;
  notification?: number;
  creatorImage?: string;
  type: ProductType | NoodleProductTypes;
  creatorName?: string;
  color?: string;
  assets?: {
    minutes?: number;
    type: AssetType;
    count?: number;
  }[];
  isDisabled?: boolean;
  isInactive?: boolean;
};

const ProfileProductIcons: FC<IconsProps> = ({
  isDisabled= false,
  isInactive = false,
  notification,
  creatorImage,
  type,
  creatorName,
  color,
  isOnCreatorPage,
  isSmall,
}): ReactElement => {
  const appearAndDisappear = {
    animate: { scale: 1 },
    exit: { scale: 0 },
    initial: { scale: 0 },
  };

  const getIcon = (): ReactElement => {
    if (type === ProductType.InPersonChat || type === NoodleProductTypes.InPersonSession) {
      return (
        <m.div className={classNames(s.library, {
          [s['library--is-disabled']]: isDisabled,
          [s['library--is-small']]: isSmall,
          [s['library--is-inactive']]: isInactive,
        })}>
          <Handshake weight="fill" style={{ opacity: 1 }} />
        </m.div>
      );
    }

    if (type === NoodleProductTypes.Consultation) {
      return (
        <m.div className={classNames(s.library, {
          [s['library--is-disabled']]: isDisabled,
          [s['library--is-small']]: isSmall,
          [s['library--is-inactive']]: isInactive,
        })}>
          <CalendarBlank weight="fill" style={{ opacity: 1 }} />
        </m.div>
      );
    }

    if (type === ProductType.LiveChat || type === NoodleProductTypes.OnlineSession) {
      return (
        <m.div className={classNames(s.library, {
          [s['library--is-disabled']]: isDisabled,
          [s['library--is-small']]: isSmall,
          [s['library--is-inactive']]: isInactive,
        })}>
          <Globe weight="fill" style={{ opacity: 1 }} />
        </m.div>
      );
    }

    if (type === ProductType.Plans) {
      return (
        <m.div className={classNames(s.library, {
          [s['library--is-disabled']]: isDisabled,
          [s['library--is-small']]: isSmall,
          [s['library--is-inactive']]: isInactive,
        })}>
          <BookmarkSimple weight="fill" style={{ opacity: 1 }} />
        </m.div>
      );
    }

    if (type === NoodleProductTypes.Subscription) {
      return (
        <m.div className={classNames(s.library, {
          [s['library--is-disabled']]: isDisabled,
          [s['library--is-small']]: isSmall,
          [s['library--is-inactive']]: isInactive,
        })}>
          <UsersThree weight="fill" style={{ opacity: 1 }} />
        </m.div>
      );
    }

    if (isOnCreatorPage && (type === ProductType.Chat || NoodleProductTypes.Lite)) {
      return (
        <m.div className={classNames(s.library, {
          [s['library--is-disabled']]: isDisabled,
          [s['library--is-small']]: isSmall,
          [s['library--is-inactive']]: isInactive,
        })}>
          <ChatsCircle weight="fill" style={{ opacity: 1 }} />
        </m.div>
      );
    }

    if ((type === ProductType.Chat || NoodleProductTypes.Lite) && creatorImage) {
      return <Image src={creatorImage} width={48} height={48} alt="" objectFit="cover" className={s.image} />;
    }

    return <UserImage color={color} size={48} name={creatorName} url={creatorImage} />;
  };

  return (
    <div className={classNames(s.wrapper, {[s['wrapper--is-small']]: isSmall})}>
      <AnimatePresence>
        {notification && (
          <m.div className={s.badge} {...appearAndDisappear}>
            <span className="caption">{notification}</span>
          </m.div>
        )}
      </AnimatePresence>
      {getIcon()}
    </div>
  );
};

export default ProfileProductIcons;
