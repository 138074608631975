import { useRouter } from 'next/router';
import { useUser, useIsUserInitialized , useUserProfile } from '@providers/Auth';
import EmbeddedAuthForm, { View } from '@components/Auth/EmbeddedAuthForm';
import AccountCompletionForm from '@components/Auth/AccountCompletionForm';
import ConnectWithCreatorWrapper from '@components/ConnectWithCreatorWrapper';
import getCreator from '@tsClient/getCreator';
import useNoodleApi from '@hooks/useNoodleApi';
import { useEffect } from 'react';
import ProgressIndicator from '@components/ProgressIndicator';
import BasicWrapper from './BasicWrapper';
import WrapperWithHeader from './WrapperWithHeader';
import pickInitialView from './pickInitialView';

import s from './LoggedInUserGate.module.scss';

type Props = {
  creator?: { slug: string } | Parameters<typeof ConnectWithCreatorWrapper>[0]['creator'] | null;
  initialView?: View;
  isAnonymousAllowed: boolean;
  header?: {
    title: string;
    subtitle: string;
  } | null;
  isDependentOnProfile?: boolean;
};

const LoggedInUserGate: React.FC<Props> = ({
  isAnonymousAllowed,
  children,
  creator,
  header,
  isDependentOnProfile = false,
  initialView: initialViewInput,
}) => {
  const profile = useUserProfile();
  const [user] = useUser();
  const router = useRouter();
  const isUserInitialized = useIsUserInitialized();
  const { data: creatorData, getData: getCreatorBySlugFn } = useNoodleApi(getCreator);

  useEffect(() => {
    if (creator?.slug) {
      getCreatorBySlugFn({ creatorSlug: creator.slug });
    }
  }, [creator?.slug, getCreatorBySlugFn]);

  if (!isUserInitialized) {
    return null;
  }

  const initialView = pickInitialView({
    creator,
    initialView: initialViewInput,
    query: router.query,
    user,
  });

  if (isDependentOnProfile && user && !profile) {
    return (<ProgressIndicator isCentered />);
  }

  if (!user || (user.isAnonymous && !isAnonymousAllowed)) {
    if (header) {
      return (
        <WrapperWithHeader
          title={header.title}
          subtitle={header.subtitle}
          creatorAvatar={{
            color: creatorData?.primaryColour?.hex,
            name: creatorData?.name || '',
            url: creatorData?.person?.image?.url,
          }}
        >
          <EmbeddedAuthForm creatorSlug={creator?.slug} defaultView={initialView} />
        </WrapperWithHeader>
      );
    }

    if (creator) {
      return (
        <BasicWrapper>
          <ConnectWithCreatorWrapper creator={creator}>
            <EmbeddedAuthForm creatorSlug={creator?.slug} defaultView={initialView} />
          </ConnectWithCreatorWrapper>
        </BasicWrapper>
      );
    }

    return (
      <BasicWrapper>
        <EmbeddedAuthForm defaultView={initialView} />
      </BasicWrapper>
    );
  }

  if (!user.isComplete && !user.isAnonymous) {
    return (
      <div className={s.wrapper}>
        <AccountCompletionForm />
      </div>
    );
  }

  return <>{children}</>;
};

export default LoggedInUserGate;
