import { useEffect, useMemo, useRef, useState } from 'react';
import intlTelInput from 'intl-tel-input';
import 'intl-tel-input/build/css/intlTelInput.css';
import useIPGeolocation from '@hooks/useIPGeolocation';
import InputField from '../InputField';
import validate from './validate';
import s from './PhoneInput.module.scss';

type Iti = ReturnType<typeof intlTelInput>;

type Props = {
  id: string;
  value: string;
  placeholder?: string;
  onChange: (phoneNumber: string, country: string) => void;
  onError?: (error: string | null) => void;
  label: string;
  disabled?: boolean;
  required?: boolean;
  error?: string | null;
  isTouched?: boolean;
  autoFocus?: boolean;
  // When verifying a phone number a new person is created, it's not just that the phone number is verified.
  // If putting this back in we need to add a new endpoint or change PUT /auth/otp-requests to not create a new person here.
  // withVerification?: boolean;
};

// const CODE_LENGTH = 6;

const PhoneInput: React.FC<Props> = ({
  id,
  value,
  placeholder,
  label,
  onChange,
  onError,
  disabled,
  required = false,
  error,
  autoFocus = false,
  isTouched,
  // withVerification,
}) => {
  // const [initialValue] = useState(value);
  const [internalValue, setInternalValue] = useState(value);
  const [internalError, setInternalError] = useState<string | null>(null);
  // const [internalCodeError, setInternalCodeError] = useState<string | null>(null);
  // const [isSendingCode, setIsSendingCode] = useState(false);
  // const [isCodeSent, setIsCodeSent] = useState(false);
  // const [isVerified, setIsVerified] = useState(false);
  const inputRef = useRef<HTMLInputElement | null>(null);
  const itiRef = useRef<Iti | null>(null);
  const [itiIsReady, setItiIsReady] = useState(false);

  // Want to always use the latest without needing to put in useCallback/useEffect dependency lists
  const onChangeRef = useRef<Props['onChange'] | null>();
  onChangeRef.current = onChange;
  const onErrorRef = useRef<Exclude<Props['onError'], undefined> | null>();
  onErrorRef.current = onError;

  const [geolocation] = useIPGeolocation();
  const isoCode = geolocation?.country?.iso_code;
  const inputElement = inputRef.current;
  // const { getData: requestOtpFn } = useNoodleApi(tsClient.auth.requestOTP, { reportError: false });

  useEffect(() => {
    if (inputElement) {
      itiRef.current = intlTelInput(inputElement, {
        autoHideDialCode: true,
        autoPlaceholder: 'off',
        nationalMode: true,
        preferredCountries: ['us', 'gb', 'au', 'de', 'se', 'ie', 'nl'],
        utilsScript: '/intl-tel-input/utils.js',
      });
      itiRef.current.promise.then(() => setItiIsReady(true));
    }
  }, [inputElement]);

  useEffect(() => {
    if (itiIsReady && itiRef.current) {
      const phoneNumber = itiRef.current.getNumber();
      setInternalValue(phoneNumber);
      if (onChangeRef.current) {
        const countryData = itiRef.current.getSelectedCountryData();
        const country = (countryData.iso2 || '').toUpperCase();
        onChangeRef.current(phoneNumber, country);
      }
    }
  }, [itiIsReady]);

  const countryChangeListener = useMemo(
    () => () => {
      if (itiRef.current) {
        const phoneNumber = itiRef.current.getNumber();
        setInternalValue(phoneNumber);
        if (onChangeRef.current) {
          const countryData = itiRef.current.getSelectedCountryData();
          const country = (countryData.iso2 || '').toUpperCase();
          onChangeRef.current(phoneNumber, country);
        }
      }
    },
    [],
  );

  useEffect(() => {
    if (inputElement) {
      inputElement.addEventListener('countrychange', countryChangeListener);
    }

    return () => inputElement?.removeEventListener('countrychange', countryChangeListener);
  }, [inputElement, countryChangeListener]);

  useEffect(() => {
    if (isoCode && itiRef.current) {
      itiRef.current.setCountry(isoCode);
      if (onErrorRef.current) {
        onErrorRef.current(null);
      }
    }
  }, [isoCode]);

  useEffect(() => {
    if (itiRef.current) {
      const countryData = itiRef.current.getSelectedCountryData();
      const countryCode = countryData?.dialCode;
      const newError = validate({
        countryCode,
        isRequired: required,
        value: internalValue,
      });
      setInternalError(newError);
      if (onErrorRef.current) {
        onErrorRef.current(newError);
      }
    }
  }, [internalValue, required]);

  useEffect(() => {
    setInternalValue(value);
  }, [value]);
  // const {
  //   error: verifyOTPError,
  //   fetchingState: verifyOTPState,
  //   getData: verifyOTP,
  // } = useNoodleApi(tsClient.auth.verifyOTP, {
  //   reportError: false,
  // });

  // const verifyCode = async (code: string): Promise<void> => {
  //   const codeToSend = code;
  //   const response = await verifyOTP({
  //     code: codeToSend,
  //     country: itiRef.current?.getSelectedCountryData()?.iso2 || '',
  //     creatorSlug: undefined,
  //     ipAddress: geolocation?.ip || null,
  //     phoneNumber: internalValue,
  //     to: internalValue,
  //   });

  //   if (response.data) {
  //     setIsVerified(true);
  //   } else if (response.error) {
  //     setInternalCodeError(verifyOTPError?.message || 'Invalid code');
  //   }
  // };

  // const handleChangeInput: ChangeEventHandler<HTMLInputElement> = (event) => {
  const handleChangeInput = (enteredValue: string): void => {
    if (itiRef.current) {
      // if (withVerification) {
      //   setIsCodeSent(false);
      //   setIsVerified(false);
      //   setInternalCodeError(null);
      // }
      const countryData = itiRef.current.getSelectedCountryData();
      const countryCode = countryData.dialCode;
      const hasPlus = /^\+/.test(value);
      const enteredValueNoFormatting = `${hasPlus ? '+' : ''}${value.replace(/[^0-9]/g, '')}`;
      const phoneNumber = itiRef.current.getNumber() || enteredValueNoFormatting;
      const enteredValueWithCountryCode = `+${countryCode}${enteredValueNoFormatting}`;
      // keep showing without the country code if that's what was typed.
      const newValue = phoneNumber === enteredValueNoFormatting || phoneNumber === enteredValueWithCountryCode ? enteredValue : phoneNumber;
      if (onChangeRef.current) {
        const country = (countryData.iso2 || '').toUpperCase();
        onChangeRef.current(phoneNumber, country);
      }
      setInternalValue(newValue);
    }
  };

  // const sendVerificationCode = async (): Promise<void> => {
  //   setIsSendingCode(true);
  //   if (itiRef.current) {
  //     const phoneNumber = itiRef.current.getNumber();
  //     if (phoneNumber) {
  //       const { error: err, data } = await requestOtpFn({
  //         channel: 'sms',
  //         phoneNumber,
  //       });
  //       setIsSendingCode(false);
  //       if (data?.channel === 'sms') {
  //         setIsCodeSent(true);
  //       }
  //       if (err?.type === 'InvalidPhoneNumberError') {
  //         setInternalError("Invalid number. Make sure your number is correct and that you've selected the proper country from the drop down list.");
  //       } else if (err?.type === 'IsLandlinePhoneNumberError') {
  //         setInternalError('Landlines not supported. Please provide another number or contact  support@noodle.shop for help.');
  //       }
  //     }
  //   }
  // };

  const name = id;

  return (
    <div className={s.wrapper}>
      <div className={s.phoneInputWrapper}>
        <InputField
          id={id}
          name={name}
          values={{ [name]: internalValue }}
          formErrors={{ [name]: error || internalError }}
          onChange={handleChangeInput}
          placeholder={placeholder}
          type={'text'}
          label={label}
          isTouched={isTouched}
          ref={inputRef}
          disabled={disabled}
          autoFocus={autoFocus}
          inputMode="tel"
          hasFixedHeight={false}
          required={required}
        />
        {/* withVerification && internalValue.length > 0 && value !== initialValue && !internalError && (
          <Buttons
            disabled={isVerified}
            className={s.verify}
            onClick={sendVerificationCode}
            isThird
            isShimmering={isSendingCode}
            iconBefore={(isVerified && <CheckCircle weight="fill" />) || undefined}
          >
            {(isCodeSent && !isVerified && 'Resend code') || (isVerified && 'Verified') || (isSendingCode && 'Sending code...') || 'Verify'}
          </Buttons>
        ) */}
      </div>

      {/* withVerification && isCodeSent && !isVerified && (
        <>
          <PasscodeInput
            label="Verification code"
            length={6}
            id={`${id}-verification-code`}
            onChange={async codeValue => {
              if (codeValue.length === CODE_LENGTH) {
                await verifyCode(codeValue);
              }
            }}
          />
          {internalCodeError && !verifyOTPState.isFetching && <FieldError>{internalCodeError}</FieldError>}
          {verifyOTPState.isFetching && (
            <p className={s.verifyingCode}>
              <ProgressIndicator size={12} />
              Verifying...
            </p>
          )}
        </>
      ) */}
    </div>
  );
};

export default PhoneInput;
