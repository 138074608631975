import { useState } from 'react';
import { View } from '../types';
import RequestOTP from '../RequestOTP';
import VerifyOTP from '../VerifyOTP';

type Props = {
  creatorSlug?: string;
  onChangeView: (view: View) => void;
  phoneNumber?: string;
};

const SignInWithPhone: React.FC<Props> = ({ creatorSlug, phoneNumber: initialPhoneNumber }) => {
  const [phoneNumber, setPhoneNumber] = useState('');
  const [country, setCountry] = useState('');
  const [recipient, setRecipient] = useState('');
  const [canSendViaEmail, setCanSendViaEmail] = useState(false);

  const handleSentCode: Parameters<typeof RequestOTP>[0]['onSuccess'] = (otpData) => {
    setPhoneNumber(otpData.phoneNumber);
    setCountry(otpData.country);
    setRecipient(otpData.recipient);
    setCanSendViaEmail(otpData.canSendViaEmail);
  };

  if (!phoneNumber) {
    return (
      <RequestOTP onSuccess={handleSentCode} initialPhoneNumber={initialPhoneNumber}/>
    );
  }

  return (
    <VerifyOTP
      phoneNumber={phoneNumber}
      recipient={recipient}
      country={country}
      authCodeLength={6}
      canSendViaEmail={canSendViaEmail}
      creatorSlug={creatorSlug}
    />
  );
};

export default SignInWithPhone;
