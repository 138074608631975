import { logError } from "@providers/ErrorTracking";

type Field =
  | 'sign-up-title'
  | 'sign-up-name-label'
  | 'sign-up-name-placeholder';

type Props = {
  field: Field;
  isCreator?: boolean;
  creatorSlug?: string | null; // @todo creator would be better....
};

const pickText = ({ field, isCreator, creatorSlug }: Props): string => {

  if (field === 'sign-up-title') {
    if (isCreator) {
      return 'Create Your Noodle Account';
    }
    return 'Sign Up';
  }

  if (field === 'sign-up-name-placeholder') {
    if (creatorSlug === 'compleximmigration') {
      return 'Name (Your name or Business/Organization name)';
    }
    return 'Enter your name';
  }

  if (field === 'sign-up-name-label') {
    if (creatorSlug === 'compleximmigration') {
      return 'Your name or Business/Organization name';
    }
    return 'Your name';
  }

  logError(new Error('Unexpected field when picking text'), { field });
  return '';
};

export default pickText;
