import { ReactElement, ReactNode, useEffect, useState } from 'react';
import classNames from 'classnames';
import s from './Tabs.module.scss';

type TabsProps<INPUT_ID_TYPE extends string> = {
  inputName: string;
  inputs: Array<{
    id: INPUT_ID_TYPE;
    label: ReactNode;
  }>;
  onChange: (id: INPUT_ID_TYPE) => void;
  className?: string;
  checked?: INPUT_ID_TYPE;
};

const Tabs = <INPUT_ID_TYPE extends string>({
  inputName,
  inputs,
  onChange,
  className,
  checked,
}: TabsProps<INPUT_ID_TYPE>): ReactElement => {
  const [activeTab, setActiveTab] = useState<INPUT_ID_TYPE | undefined>(checked || (inputs.length > 0 ? inputs[0].id : undefined));

  useEffect(() => {
    if (checked) {
      setActiveTab(checked);
    }
  }, [checked, setActiveTab]);

  return (
    <fieldset className={classNames(className, s.tabs)}>
      {inputs.map((i) => (
        <label key={i.id}>
          <input
            type="radio"
            id={i.id}
            name={inputName}
            value={i.id}
            checked={activeTab === i.id}
            onChange={() => {
              setActiveTab(i.id);
              onChange(i.id);
            }}
          />
          {i.label}
        </label>
      ))}
    </fieldset>
  );
};

export default Tabs;
