import UserImage from '@components/UserImage';
import BackButton from '@/components/BackButton';
import s from './LoggedInUserGate.module.scss';

type Props = {
  title: string;
  subtitle: string;
  creatorAvatar?: {
    color?: string;
    url?: string;
    name?: string;
  };
};

const WrapperWithHeader: React.FC<Props> = ({ title, subtitle, children, creatorAvatar }) => (
  <div className={s.wrapperWithHeader}>
    <BackButton className={s.back} />
    <div className={s.header}>
      {creatorAvatar && <UserImage size={48} {...creatorAvatar} />}
      <h1 className="heading-sm">{title}</h1>
      <p className="caption">{subtitle}</p>
    </div>
    {children}
  </div>
);

export default WrapperWithHeader;
