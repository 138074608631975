const CREATORS_WITH_PHONE_LOGIN = [
  'danny-maude',
  'mike-dolce',
  'jordan-lawley',
  'kaspar-thomas',
  'alitaylorgolf',
  'nattiegolf',
  'on-the-outside',
];

const getLoginsEnabled = ({ creatorSlug }: {
  creatorSlug?: string | null;
}): { isEmailLoginEnabled: boolean, isPhoneLoginEnabled: boolean } => ({
  isEmailLoginEnabled: true,
  isPhoneLoginEnabled: !creatorSlug || CREATORS_WITH_PHONE_LOGIN.includes(creatorSlug),
});

export default getLoginsEnabled;