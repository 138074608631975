import { ChangeEventHandler, FC } from 'react';
import classNames from 'classnames';
import s from './Switch.module.scss';

const Switch: FC<{
  label: string;
  name: string;
  isChecked: boolean;
  onChange: (isSet: boolean) => void;
  className?: string;
}> = ({ className, label, name, isChecked, onChange }) => {
  const handleChange: ChangeEventHandler<HTMLInputElement> = (event) => {
    // Can't preventDefault for css (:has(input:checked) to work
    onChange(Boolean(event.target.checked));
  };

  return (
    <label className={classNames(s.switch, className)}>
      <div className={s.switchControl}>
        <div />
      </div>
      {label}
      <input name={name} type="checkbox" checked={isChecked} onChange={handleChange} />
    </label>
  );
};

export default Switch;
