import Ghost from '@components/Icons/Ghost';
import Lightning from '@components/Icons/Lightning';
import Mountains from '@components/Icons/Mountains';
import SketchLogo from '@components/Icons/SketchLogo';
import { ReactElement } from 'react';
import s from './SaaSTierIcon.module.scss';

type Props = {
  label: string;
  hasCircle?: boolean;
};

const getIcon = (label: Props['label']): ReactElement => {
  switch (label) {
  case 'Free':
    return <Ghost weight="fill" size={32} color="var(--color-noodle)" />;
  case 'Growth':
    return <Lightning weight="fill" size={32} color="var(--color-success)" />;
  case 'Scale':
    return <Mountains weight="fill" size={32} color="var(--color-warning)" />;
  case 'Superstar':
    return <SketchLogo weight="fill" size={32} color="var(--color-error)" />;
  default:
    return <SketchLogo weight="fill" size={32} color="var(--color-error)" />;
  }
};

const SaaSTierIcon = ({ label, hasCircle }: Props): ReactElement => {
  const color = (label === 'Free' && 'noodle') || (label === 'Growth' && 'success') || (label === 'Scale' && 'warning') || (label === 'Superstar' && 'error');
  if (hasCircle) {
    return (
      <div className={s.circle} style={{ backgroundColor: `var(--color-${color})` }}>
        {getIcon(label)}
      </div>
    );
  }
  return getIcon(label);
};

export default SaaSTierIcon;
