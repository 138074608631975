import { FC, useRef } from 'react';
import * as ApiModels from '@typings/document-api-models';
import * as format from '@format';
import FileType from '@components/Icons/FileType';
import { useIsTextTruncated } from '@hooks';
import s from './File.module.scss';
import Button from '../Button';

type Props = {
  document: Pick<ApiModels.Document, 'id' | 'fileName' | 'createdAt' | 'uploadedBy' | 'mimeType' | 'uploadedAt'>;
  onDownload?: () => void;
  onReject?: () => void;
  onReUpload?: () => void;
  onOpenAiReader?: () => void;
  onRename?: () => void;
};

const File: FC<Props> = ({document, onDownload, onReUpload, onReject, onOpenAiReader, onRename }) => {
  const labelRef = useRef(null);
  const isLabelTruncated = useIsTextTruncated(labelRef);
  return (
    <div className={s.container}>
      <div className={s.document}>
        <FileType size={20} mimeType={document.mimeType} isEmpty={false} />
        <div className={s.infoText}>
          <p className={s.fileName} ref={labelRef} title={isLabelTruncated ? document.fileName : undefined}>
            {document.fileName}
          </p>
          <p className={s.uploadedBy}>
            <span>{document.uploadedBy.name}</span> {format.datetime.timeSince({ date: document.createdAt })} ago
          </p>
        </div>
        <div className={s.buttons}>
          {onOpenAiReader && document.mimeType.includes('image') && (
            <Button onClick={onOpenAiReader} size="sm" variant="secondary">
              Open AI Reader
            </Button>
          )}
          {onReUpload && (
            <Button onClick={onReUpload} size="sm" variant="primary">
              Re-Upload
            </Button>
          )}
          {onDownload && (
            <Button onClick={onDownload} size="sm" variant="secondary">
              Download
            </Button>
          )}
          {onRename && (
            <Button onClick={onRename} size="sm" variant="primary">
              Rename
            </Button>
          )}
          {onReject && (
            <Button onClick={onReject} size="sm" variant="destructive">
              Reject
            </Button>
          )}
        </div>
      </div>
    </div>
  );
};

export default File;
