import type { ParsedUrlQuery } from 'querystring';
import { AuthUser } from '@providers/Auth';
import { View } from '../types';

type Props = {
  creator?: { slug: string } | null;
  initialView?: View;
  query?: ParsedUrlQuery;
  user?: Pick<NonNullable<AuthUser>, 'isAnonymous'> | null;
};

const getViewFromQueryString = (query: ParsedUrlQuery | undefined): View | null => {
  if (query) {
    if (query.authView === 'signUp' || query.authView === 'signIn' || query.authView === 'resetPassword' || query.authView === 'forgotPassword') {
      return query.authView;
    }
  }
  return null;
};

const pickInitialView = ({
  creator,
  initialView,
  query,
  user,
}: Props): View | null => {
  const fromQS = getViewFromQueryString(query);
  if (fromQS) {
    return fromQS;
  }

  if (initialView) {
    return initialView;
  }

  if (creator) {
    return 'signIn';
  }

  if (user?.isAnonymous) {
    return 'signUp';
  }

  return null;
};

export default pickInitialView;