import * as validation from '../validate';

export const emailRegExp = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

type Options = {
  isRequired: boolean;
};

const myValidate = (value: string | null | undefined): string | null => (value === null || value === undefined || value === '' || emailRegExp.test(value))
  ? null
  : 'Not a valid email.';

const validate = (value: string, options: Options): string | null => (
  (options.isRequired && validation.isRequired(value))
  || myValidate(value)
);

export default validate;
