import { useCallback, useEffect, useState, useContext } from 'react';
import { useQueryState } from 'nuqs';
import { mixpanelAlias, mixpanelTrack } from '@providers/Mixpanel';
import { logError } from '@providers/ErrorTracking';
import { clearLocalStorageState } from '@providers/LocalStorageState';
import useNoodleApi from '@hooks/useNoodleApi';
import { useToast, useUser } from '@hooks';
import * as tsClient from '@tsClient';
import Buttons from '@components/Buttons';
import Button from '@components/DesignLibrary/Button';
import DeviceMobile from '@components/Icons/DeviceMobile';
import FieldError from '@components/FieldError';
import Link from '@components/CustomLink';
import EmailInput from '@components/FormFields/EmailInput';
import PasswordInput from '@components/FormFields/PasswordInput';
import useIPGeolocation from '@hooks/useIPGeolocation';
import PurchaseConfirmationCard from '@components/PurchaseConfirmationCard';
import { Currency } from '@typings/graphql-models';
import { PasswordValidation } from '@components/FormFields/PasswordInput/validate';
import TextInput from '@components/FormFields/TextInput';
import ProgressIndicator from '@components/ProgressIndicator';
import CountrySelect, { DEFAULT_COUNTRY } from '@components/FormFields/CountrySelect';
import LanguageSwitch from '@/components/LanguageSwitch';
import TranslationContext from '@/providers/TranslationProvider/TranslationContext';
import PhoneInput from '@/components/FormFields/PhoneInput';
import CheckBox from '@/components/DesignLibrary/Atoms/CheckBox';
import { getUrl, IDENTIFIERS } from '@/helpers/urlsHelper';
import s from '../Auth.module.scss';
import { View } from '../types';
import generateErrorMessage from './generateErrorMessage';
import TermsAndPolicy from '../TermsAndPolicy';
import pickText from '../../../pickText';

type Props = {
  code?: string | null;
  creatorSlug?: string | null;
  onChangeView: (view: View) => void;
  isEmbedded?: boolean;
  isCreator?: boolean;
  hasFinishedOnboarding?: boolean;
  vendor?: string | null;
  activationEmail?: string | null;
  activationName?: string | null;
  priceId?: string | null;
  showTerms?: boolean;
  hideLegend?: boolean;
};

const SignUpForm: React.FC<Props> = ({
  activationEmail,
  activationName,
  code,
  creatorSlug,
  onChangeView,
  hasFinishedOnboarding,
  isEmbedded,
  isCreator,
  vendor,
  priceId,
  showTerms = true,
  hideLegend,
}) => {
  const [queryEmail] = useQueryState('email');
  const [queryName] = useQueryState('name');
  const [businessCategory] = useQueryState('businessCategory');
  const thisEmail = queryEmail || activationEmail || '';
  const thisName = queryName || activationName || '';

  const [email, setEmail] = useState(thisEmail);
  const [password, setPassword] = useState('');
  const [name, setName] = useState(thisName);
  const [phone, setPhone] = useState('');
  const [optedIntoSms, setOptedIntoSms] = useState(false);
  const addToast = useToast();
  const [_user, _setUser, setUserByToken] = useUser();
  const [geolocation] = useIPGeolocation();
  const [onboardingFlow] = useQueryState('onboardingFlow');

  const [nameError, setNameError] = useState<string | null>('');
  const [emailError, setEmailError] = useState<string | null>('');
  const [phoneError, setPhoneError] = useState<string | null>('');
  const [passwordError, setPasswordError] = useState<PasswordValidation>();
  const [allAreTouched, setAllAreTouched] = useState<boolean>(false);
  const [country, setCountry] = useState<string>(geolocation?.country?.iso_code || DEFAULT_COUNTRY.value);
  const { language, setLanguage } = useContext(TranslationContext);

  const {
    data: signUpResponse,
    error: signUpError,
    fetchingState: signInState,
    getData: passwordSignUp,
  } = useNoodleApi(tsClient.auth.passwordSignUp, {
    healthMonitor: { name: 'sign-up' },
    toastOnError: true,
  });

  const { data: price, getData: getPrice } = useNoodleApi(tsClient.getPrice);
  const { data: creator, getData: getCreatorFn } = useNoodleApi(tsClient.getCreator);

  const handleTapSignIn = (): void => onChangeView('signIn');

  const handleEmailChange = (val: string): void => {
    setEmail(val);
  };

  const handlePasswordChange = (val: string): void => {
    setPassword(val);
  };

  const handleNameChange = (val: string): void => {
    setName(val);
  };

  const handleCountryChange = useCallback((val: string): void => {
    setCountry(val);
  }, []);

  const handleSignUp: React.FormEventHandler<HTMLFormElement> = async event => {
    if (event && event.preventDefault) {
      event.preventDefault();
      event.stopPropagation();
    }

    if (emailError?.length || !passwordError?.isAllowed || nameError?.length) {
      setAllAreTouched(true);
      return;
    }
    passwordSignUp({
      businessCategory: businessCategory || undefined,
      code,
      country,
      creatorSlug: creatorSlug || undefined,
      hasFinishedOnboarding,
      identifier: email,
      ipAddress: geolocation?.ip || undefined,
      isCreator,
      name,
      optedIntoSms,
      password,
      phoneNumber: phone,
      preferredLanguage: language,
      vendor,
    });
  };

  useEffect(() => {
    if (geolocation?.country?.iso_code) {
      setCountry(onboardingFlow === 'attorney' ? 'US' : geolocation.country.iso_code);
    }
  }, [geolocation?.country?.iso_code, onboardingFlow]);

  useEffect(() => {
    if (!signUpResponse) {
      return;
    }

    const { token, isExistingUser, failedClaimCode } = signUpResponse;
    clearLocalStorageState(); // @todo - shouldn't be necessary because of useEffect in Provider, but need to test before removing.
    const user = setUserByToken(token);
    if (failedClaimCode) {
      addToast(useToast.ToastTypeVariants.ERROR, 'Failed to claim code');
    }
    if (user) {
      mixpanelTrack(isCreator ? 'Creator Sign Up' : 'Sign Up', {
        method: 'Email and password',
      });
      if (!isExistingUser) {
        mixpanelTrack('Created Account', {
          creationMethod: isCreator ? 'Creator Signup (Signup)' : 'Signup',
        });
        mixpanelAlias(user?.id);
      }
    } else {
      logError(new Error('Invalid token returned'));
    }
  // isCreator is only used in mixpanelTrack ok to ignore in dep list
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [addToast, signUpResponse, setUserByToken]);

  useEffect(() => {
    if (signUpError) {
      mixpanelTrack('Sign up with email error', { reason: signUpError.message });
    }
  }, [signUpError]);

  useEffect(() => {
    if (priceId) {
      getPrice({ priceId });
    }
  }, [priceId, getPrice]);

  useEffect(() => {
    if (creatorSlug) {
      getCreatorFn({ creatorSlug });
    }
  }, [creatorSlug, getCreatorFn]);

  const errorMessage = generateErrorMessage(signUpError);

  return (
    <form onSubmit={handleSignUp} style={{ width: '100%' }}>
      <fieldset className={isEmbedded ? s.formwrapperEmbedded : s.formwrapper}>
        {vendor === 'appsumo'
          ? (
            <>
              <legend>Thank you, Sumo-ling!</legend>
              <p className="body-sm">Your order is confirmed. Finish creating your account to access your purchase.</p>
            </>
          )
          : (
            <>{!isEmbedded && !hideLegend && <legend>{pickText({ creatorSlug, field: 'sign-up-title', isCreator })}</legend>}</>
          )}
        <TextInput
          id={'name'}
          disabled={thisName !== ''}
          value={name}
          onChange={handleNameChange}
          label={pickText({ creatorSlug, field: 'sign-up-name-label', isCreator })}
          placeholder={pickText({ creatorSlug, field: 'sign-up-name-placeholder', isCreator })}
          autoComplete={'name'}
          onError={setNameError}
          isTouched={allAreTouched}
          required
        />
        {isCreator && onboardingFlow !== 'attorney' && (
          <CountrySelect
            value={country}
            onChange={handleCountryChange}
          />
        )}
        <EmailInput
          id="email"
          disabled={thisEmail !== ''}
          value={email}
          onChange={handleEmailChange}
          label="Your email"
          placeholder="Enter your email address"
          onError={setEmailError}
          isTouched={allAreTouched}
          required
        />
        {creator && creator?.noodlePhoneNumber && (
          <>
            <PhoneInput
              id='phone'
              value={phone}
              onChange={setPhone}
              label={'Phone'}
              onError={setPhoneError}
              error={phoneError}
            />
            <div className={s.optInSms}>
              <div className={s.optInSmsBg} />
              <div className={s.optInSmsTitle}><div className={s.optInSmsBg} /><DeviceMobile weight='fill' /> Enable SMS notifications</div>
              <CheckBox
                id={'optedIntoSms'}
                isChecked={optedIntoSms}
                onChange={() => setOptedIntoSms(prev => !prev) }
                label={`Opt-in and ${creator.name} will send you timely notifications and reminders about your case. Messages will come from ${creator?.noodlePhoneNumber}. No marketing materials will be sent to you.`}
              />
              <div className={s.links}>
                <Link to={getUrl(IDENTIFIERS.CREATOR_MESSAGING_TERMS, { creatorSlug: creator.slug })} newTab>Terms of Service</Link>
                {' | '}
                <Link to={getUrl(IDENTIFIERS.PRIVACY)} newTab>Privacy Policy</Link>
              </div>
            </div>
          </>
        )}
        <PasswordInput
          id="password"
          value={password}
          onChange={handlePasswordChange}
          checkStrength={true}
          label="Password"
          placeholder="Enter your password"
          onError={setPasswordError}
          isTouched={allAreTouched}
          required
        />
        {errorMessage && <FieldError>Error: {errorMessage}</FieldError>}
        <div className={s.lang}>
          <LanguageSwitch currentLanguage={language} onChange={setLanguage} />
        </div>
        <Button
          variant='primary'
          size='lg'
          type="submit"
          loading={signInState.isFetching}
          className={s.submitButton}
          disabled={
            (nameError !== null)
            || (emailError !== null)
            || passwordError?.isAllowed === false
            || signInState.isFetching
          }
        >
          Sign Up
        </Button>
        {showTerms && <TermsAndPolicy />}
        {!vendor && (
          <small>
            Already have an account?{' '}
            <Buttons isWrapper onClick={handleTapSignIn} disabled={signInState.isFetching}>
              Sign in
            </Buttons>
            .
          </small>
        )}
        {vendor === 'appsumo' && priceId && (
          <>
            {!price ? <ProgressIndicator /> : <PurchaseConfirmationCard paymentType='appsumo' isSaaSProduct tier={price.creatorTier?.creatorTierType} price={{ currency: Currency.Usd, price: price.price }} />}
          </>
        )}
      </fieldset>
    </form>
  );
};

export default SignUpForm;
