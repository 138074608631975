import * as ApiModels from '@typings/api-models';
import removeNullish from './removeNullish';
import { getUrl, IDENTIFIERS } from './urlsHelper';

const userWorkflowToContext = (
  userWorkflow: {
    person: Pick<ApiModels.Person, 'id' | 'name'>;
    context: Pick<ApiModels.UserWorkflowContext, 'id' | 'label' | 'value' | 'type'>[];
  }| null,
  isCustomer?: boolean,
): Array<{
  id: string;
  label: string;
  value: string;
  href?: string;
}> => {

  if (!userWorkflow) {
    return [];
  }

  return [
    ...(isCustomer || !('id' in userWorkflow.person))
      ? []
      : [{
        href: getUrl(IDENTIFIERS.DASHBOARD_MEMBER, { id: userWorkflow.person.id }),
        id: userWorkflow.person.id,
        label: 'Contact',
        value: userWorkflow.person.name || '',
      }],
    ...(userWorkflow.context
      ? userWorkflow.context.map(c => {
        if (c.label && c.value) {
          return {
            id: c.id,
            label: c.label,
            value: c.value,
          };
        }
        return null;
      })
      : []
    ).filter(removeNullish),
  ];
};
export default userWorkflowToContext;
