import ApiError from "@helpers/ApiError";

const generateErrorMessage = (error: ApiError | null): string | null => {
  if (!error) {
    return null;
  }

  if (
    error.type === 'ExistingUserError'
    || (error.type === 'LoginFailedError' && typeof error?.data === 'object' && !Array.isArray(error.data) && error?.data?.reason === 'Wrong password for existing user')
  ) {
    return `This ${(error?.data as string[])[0] === 'phone' ? 'phone number' : 'email'} is already in use, please sign-in or reset your password using Forgot Password`;
  }
  if (error.errors?.[0]?.includes?.('not currently supported by Stripe')) {
    return error.errors[0];
  }

  return error ? error.message : null;
};

export default generateErrorMessage;
