import { FC, ReactElement, useEffect, useRef, useState } from 'react';
import DotsThreeCircle from '@components/Icons/DotsThreeCircle';
import { Icon } from '@phosphor-icons/react';
import Tooltip from '@components/Tooltip';
import classNames from 'classnames';
import s from './SecondaryActions.module.scss';

type Props = {
  ariaLabel: string;
  options: Array<{
    value: string;
    icon?: Icon;
    disabled?: boolean;
    destructive?: boolean;
  }>;
  onSelect: (value: string) => void | Promise<void>;
  position?: 'top-left' | 'top-right' | 'bottom-left' | 'bottom-right' | 'static';
  customIcon?: Icon;
  hideTooltip?: boolean;
  className?: string;
  compact?: boolean;
};

const SecondaryActions: FC<Props> = ({ ariaLabel, options, onSelect, position, customIcon, hideTooltip, className, compact }) => {
  const [isOpen, setIsOpen] = useState(false);
  const menuRef = useRef<HTMLUListElement>(null);
  const triggerRef = useRef<HTMLButtonElement>(null);

  useEffect(() => {
    if (position === 'static') return () => {};
    const handleClickOutside = (event: MouseEvent): void => {
      if (menuRef.current && !menuRef.current.contains(event.target as Node) && !triggerRef.current?.contains(event.target as Node)) {
        setIsOpen(false);
      }
    };
    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, [menuRef, setIsOpen, position]);

  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent): void => {
      if (event.key === 'Escape') {
        setIsOpen(false);
      }
    };
    document.addEventListener('keydown', handleKeyDown);
    return () => document.removeEventListener('keydown', handleKeyDown);
  }, [setIsOpen]);

  const IconComponent = customIcon || DotsThreeCircle;

  const TooltipComponent = !hideTooltip && !isOpen
    ? (props: { children: ReactElement }) => <Tooltip text={ariaLabel} alignment="center" className={s.tooltip} {...props} />
    : 'div';

  if (options.length === 0) return null;

  return (
    <div
      className={classNames(s.container, compact && s.containerCompact, className)}
      style={{
        ...(position === 'static'
          ? {
            alignItems: 'flex-start',
            flexDirection: 'column',
            height: 'auto',
            marginTop: '-10px',
            width: '200px',
          }
          : {}),
      }}
    >
      <TooltipComponent>
        <button type="button" ref={triggerRef} aria-label={ariaLabel} className={s.trigger} onClick={() => setIsOpen(!isOpen)}>
          <IconComponent weight={isOpen ? 'fill' : 'regular'} size={compact ? 20 : 24} />
        </button>
      </TooltipComponent>
      {isOpen && (
        <ul
          ref={menuRef}
          className={classNames(s.menu, position === 'static' && s.menuStatic, compact && s.menuCompact)}
          {...(position && {
            style: {
              bottom: position?.includes('top') ? 40 : undefined,
              left: position?.includes('left') ? 0 : undefined,
              right: position?.includes('right') ? 0 : undefined,
              top: position?.includes('top') ? 'unset' : undefined,
            },
          })}
        >
          {options.map(option => (
            <li key={option.value}>
              <button
                className={s.menuItem}
                onClick={() => {
                  onSelect(option.value);
                  setIsOpen(false);
                }}
                disabled={option.disabled}
                style={{ color: option.destructive ? 'var(--color-error)' : 'var(--color-gray-100)' }}
              >
                {option.icon && <option.icon size={16} weight="fill" />}
                {option.value}
              </button>
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};

export default SecondaryActions;
