import { JWT_COOKIE_NAME, NOODLE_DOCUMENT_HOST } from '@configuration/client';
import { getCookiesAsJson } from '@providers/Auth';

type Props = {
  id: string;
  accessToken?: string | null;
};
const preloadDocument = async ({ id, accessToken }: Props): Promise<Blob> => {
  let uri = `${NOODLE_DOCUMENT_HOST}/documents/${id}/raw`;
  if (accessToken) {
    uri = `${uri}?accessToken=${accessToken}`;
  }
  const response = await fetch(uri, {
    headers: {
      Authorization: getCookiesAsJson()[JWT_COOKIE_NAME] || window.httpToken || '',
    },
  });
  const blob = await response.blob();
  return blob;
};

export default preloadDocument;
