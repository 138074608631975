import CheckBoxBlank from '@components/Icons/CheckBoxBlank';
import CheckBoxChecked from '@components/Icons/CheckBoxChecked';
import CheckCircle from '@components/Icons/CheckCircle';
import Circle from '@components/Icons/Circle';

type Size = 'sm' | 'md';
type Props = {
  isChecked: boolean;
  size: Size;
  style: 'square' | 'round';
};

const sizeMap: Record<Size, number> = {
  md: 22,
  sm: 16,
};

const VisualBox: React.FC<Props> = ({ isChecked, size, style }) => {
  const sizeNumber = sizeMap[size];

  const props = { size: sizeNumber, style: { minWidth: sizeNumber } };

  if (style === 'round') {
    return isChecked ? <CheckCircle {...props} weight="fill" /> : <Circle {...props} />;
  }

  return isChecked ? <CheckBoxChecked {...props} weight="fill" /> : <CheckBoxBlank {...props} />;
};

export default VisualBox;
