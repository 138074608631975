import { FC } from 'react';
import CustomLink from '@components/CustomLink';
import s from './TermsAndPolicy.module.scss';

const TermsAndPolicy: FC<{ isSignIn?: boolean }> = ({ isSignIn }) => (
  <p className={s.wrapper}>
    By signing {isSignIn ? 'in' : 'up'}, you agree to our <CustomLink to="/terms-and-conditions">Terms of Use</CustomLink> and{' '}
    <CustomLink to="/privacy-policy">Privacy Policy</CustomLink>.
  </p>
);

export default TermsAndPolicy;
