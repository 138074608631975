import { FormEvent, useCallback, useEffect, useRef, useState } from 'react';
import Eye from '@components/Icons/Eye';
import EyeSlash from '@components/Icons/EyeSlash';
import InputField from '../InputField';
import validate, { defaultValidation, PasswordValidation } from './validate';
import s from './PasswordInput.module.scss';

type Props = {
  id: string;
  value: string;
  placeholder?: string;
  onChange: (newValue: string) => void;
  onError?: (error: PasswordValidation) => void;
  checkStrength?: boolean;
  label: string;
  onForgotPasswordClick?: (e: FormEvent) => void;
  isTouched?: boolean;
  error?: string | null;
  required?: boolean;
  autocomplete?: string;
};

const PasswordInput: React.FC<Props> = ({
  id,
  placeholder = 'Password',
  value,
  onChange,
  onError,
  checkStrength,
  label,
  onForgotPasswordClick,
  error,
  isTouched,
  required = false,
  autocomplete,
}) => {
  const [passwordValidation, setPasswordValidation] = useState<PasswordValidation>(defaultValidation);
  const [showPassword, setShowPassword] = useState(false);

  // Want to always use the latest without needing to put in useCallback/useEffect dependency lists
  const onChangeRef = useRef<Props['onChange'] | null>();
  onChangeRef.current = onChange;
  const onErrorRef = useRef<Exclude<Props['onError'], undefined> | null>();
  onErrorRef.current = onError;

  useEffect(() => {
    const newError = validate(value, { isRequired: required });
    setPasswordValidation(newError);
    if (onErrorRef.current) {
      onErrorRef.current(newError);
    }
  }, [value, checkStrength, required]);

  const handleToggleShow = useCallback((): void => {
    setShowPassword(oldValue => !oldValue);
  }, []);

  const handleChange = useCallback((newValue: string): void => {
    if (onChangeRef.current) {
      onChangeRef.current(newValue);
    }
  }, []);

  const attrAutoComplete = autocomplete != null ? { autocomplete } : {};

  const name = id;

  return (
    <div className={s['password-input']}>
      <InputField
        id={id}
        name={name}
        values={{ [name]: value }}
        formErrors={checkStrength ? { [name]: error || passwordValidation.message } : {}}
        onChange={handleChange}
        placeholder={placeholder}
        type={showPassword ? 'text' : 'password'}
        iconAfter={(showPassword
          ? <EyeSlash />
          : <Eye />
        )}
        onAfterIconClick={handleToggleShow}
        label={label}
        hasFixedHeight={false}
        progress={checkStrength ? passwordValidation.progress : undefined}
        isTouched={isTouched}
        {...attrAutoComplete}
      />
      {onForgotPasswordClick && (
        <button type='button' onClick={onForgotPasswordClick} className={s['forgot-password']}>
          Forgot password?
        </button>
      )}
    </div>
  );
};

export default PasswordInput;
