import { createContext } from 'react';
import * as tsClient from '@tsClient';
import { GetData as GetNoodleApiData } from '@hooks/useNoodleApi/typings';
import ApiError from '@helpers/ApiError';

type GetAccessToken = (body: { creatorId: string; personId: string; password: string }) => (
  ReturnType<GetNoodleApiData<typeof tsClient.documentAccess.getDocumentAccessToken>>
);

type DocumentAccessContextType = {
  accessToken: string | null;
  isInContext: boolean;
  getAccessToken: GetAccessToken;
};

const DocumentAccessContext = createContext<DocumentAccessContextType>({
  accessToken: null,
  getAccessToken: async () => ({ data: null, error: ApiError.create(new Error('Not hooked up')) }),
  isInContext: false,
});

export default DocumentAccessContext;