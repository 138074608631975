type PasswordStrength = 'no password' | 'very weak' | 'weak' | 'medium' | 'strong' | 'very strong';

const MIN_LENGTH = 8;
const SYMBOLS_REGEX = /([!@#$%^&*()=+[\]{}\\|,<.>/?;:'"`~\-_€£¥₹§±])/g;

type Options = {
  isRequired: boolean;
};

export type PasswordValidation = {
  message: string | null;
  strength: PasswordStrength;
  progress: number;
  isAllowed: boolean;
};

type InternalePasswordValidation = Omit<PasswordValidation, 'isAllowed' | 'progress'>;

const countSymbols = (password: string): number => {
  const symbolsMatch = password.match(SYMBOLS_REGEX);
  return symbolsMatch ? symbolsMatch.length : 0;
};

const calculateIsAllowed = ({ strength }: InternalePasswordValidation): boolean => (strength === 'strong' || strength === 'very strong');

const calculateProgress = ({ strength }: InternalePasswordValidation): number => {
  if (strength === 'very strong') {
    return 100;
  }
  if (strength === 'strong') {
    return 100;
  }
  if (strength === 'medium') {
    return 75;
  }
  if (strength === 'weak') {
    return 50;
  }
  if (strength === 'very weak') {
    return 25;
  }
  return 0;
};

const extendResponse = (obj: InternalePasswordValidation): PasswordValidation => ({
  ...obj,
  isAllowed: calculateIsAllowed(obj),
  progress: calculateProgress(obj),
});

const defaultValidationInternal: InternalePasswordValidation = {
  message: null,
  strength: 'no password',
};

export const defaultValidation = extendResponse(defaultValidationInternal);

const validateBase = (password: string, options: Options): InternalePasswordValidation => {
  if (password.length === 0) {
    if (options.isRequired) {
      return {
        message: 'Required.',
        strength: 'no password',
      };
    }
    return defaultValidation;
  }

  const passwordLength = password.length;
  const numSymbols = countSymbols(password);

  if (passwordLength < MIN_LENGTH && numSymbols === 0) {
    return {
      message: `The password must have at least ${MIN_LENGTH} characters and at least 1 special symbol.`,
      strength: 'very weak',
    };
  }

  if (passwordLength < MIN_LENGTH && numSymbols > 0) {
    return {
      message: `The password must have at least ${MIN_LENGTH} characters.`,
      strength: 'medium',
    };
  }

  if (passwordLength >= MIN_LENGTH && numSymbols === 0) {
    return {
      message: `The password must have at least 1 special symbol.`,
      strength: 'weak',
    };
  }

  if (password.length > MIN_LENGTH) {
    return {
      message: null,
      strength: 'very strong',
    };
  }

  return {
    message: null,
    strength: 'strong',
  };
};

const validate = (password: string, options: Options): PasswordValidation => extendResponse(validateBase(password, options));

export default validate;
