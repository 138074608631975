import classNames from 'classnames';
import CustomLink from '@components/CustomLink';
import type { Icon } from '@phosphor-icons/react';
import ProgressIndicator from '@components/ProgressIndicator';
import { ReactElement } from 'react';
import Image from 'next/image';
import s from './Header.module.scss';

type ActionType =
  | {
      componentBefore?: ReactElement;
      disabled?: boolean;
      href: string;
      isPrimary?: never;
      isSecondary?: never;
      isDestructive?: never;
      isAi?: never;
      label: string;
      onClick?: never;
      tooltip?: string;
      isFetching?: never;
      icon?: Icon;
      iconAfter?: Icon;
      newTab?: boolean;
      className?: string;
    }
  | {
      componentBefore?: ReactElement;
      disabled?: boolean;
      href?: never;
      isPrimary?: boolean;
      isSecondary?: boolean;
      isDestructive?: boolean;
      isAi?: boolean;
      label: string;
      onClick?: () => void;
      tooltip?: string;
      isFetching?: boolean;
      icon?: Icon;
      iconAfter?: Icon;
      newTab?: never;
      className?: string;
    };

const Action: React.FC<{ action: ActionType }> = ({ action }) => {
  if ('href' in action) {
    return (
      <CustomLink
        title={action.tooltip}
        to={action.href || null}
        className={classNames(
          action.icon ? s.action__primary : s.action,
          action.isSecondary && s.action__secondary,
          action.isPrimary && s.action__primary,
          action.className,
        )}
        newTab={action.newTab}
      >
        {action.icon && <action.icon size={16} />}
        {action.label}
        {action.iconAfter && <action.iconAfter size={16} />}
      </CustomLink>
    );
  }

  return (
    <button
      title={action.tooltip}
      onClick={() => action.onClick?.()}
      disabled={action.disabled}
      className={classNames(
        (action.icon || action.isAi) ? s.action__primary : s.action,
        action.isDestructive && s.action__destructive,
        action.isSecondary && s.action__secondary,
        action.isPrimary && s.action__primary,
        action.isAi && s.action__ai,
        action.className,
      )}
      {...(action.isFetching && (action.icon || action.isPrimary || action.isSecondary || action.isDestructive) && {
        style: {
          backgroundColor: 'var(--color-gray-25)',
          color: 'var(--color-gray-75)',
          pointerEvents: 'none',
        },
      })}
    >
      {action.isAi && (
        <>
          <div className={s.action__aiBorder} />
          <div className={s.action__aiBg} />
        </>
      )}
      {action.isFetching && <ProgressIndicator size={12} />}
      {action.componentBefore && !action.isFetching && action.componentBefore}
      {action.icon && !action.isFetching && <action.icon size={16} />}
      <span className={classNames(action.isAi ? s.action__aiChildren : s.contents)}>
        {action.isAi && <Image src='/images/dashboard/ai-icon.webp' alt='' width={16} height={16} />}
        {action.label}
      </span>
      {action.iconAfter && <action.iconAfter size={16} />}
    </button>
  );
};

export default Action;
